/**
 * Copyright (C) SiteVision AB 2002-2020, all rights reserved
 *
 * Predefined contact search portlet functions.
 * @author Magnus Lövgren
 */
import $ from '@sv/jquery';
import sv from '@sv/core';
import {
   Ajax as ajax,
   ObjectUtil as objectUtil
} from '@sv/util';
import {getTemplate} from '../../util/portletUtil';

$('.sv-predefinedcontactsearch-portlet').each(function() {
   var $portlet = $(this),
      $errorDiv = $portlet.find('[data-fn-no-result-container]'),
      $hitList = $portlet.find('[data-fn-search-result-container]'),
      $loadMoreDiv = $portlet.find('[data-fn-load-more-container]'),
      handleErrors = $errorDiv.length > 0,
      portletId = objectUtil.getObjectId($portlet.attr('id')),
      hitTemplateFunction = getTemplate($portlet, 'social-entry'),
      entryConnectedTemplateFunction = getTemplate($portlet, 'social-entry-connected'),
      moreTemplateFunction = getTemplate($portlet, 'more-hits'),
      errorTemplateFunction,
      portletStateBase = '/'+ sv.PageContext.pageId +'/'+ portletId +'.json?state=',
      moreBase = portletStateBase +'ajaxMore',
      addContactBase = portletStateBase +'ajaxAddContact&newContactId=';

   if (handleErrors) {
      errorTemplateFunction = getTemplate($portlet, 'error');
   }

   // User presses "More hits" button...
   $loadMoreDiv.on('click', '[data-fn-load-more]', function() {
      var $this = $(this),
         nextHit = $this.attr('data-next-hit'),
         moreURL = moreBase + '&startAtHit=' + nextHit;

      $this.attr('disabled', 'disabled'); // Disable button ASAP

      ajax.doGet( {
         dataType: 'json',
         url: moreURL,
         success: function(result) {
            $loadMoreDiv.empty();

            if (result.hits) {
               $.each(result.hits, function(index, hit) {
                  $hitList.append(hitTemplateFunction(hit));
               });
               if (result.hasMoreHits) {
                  $loadMoreDiv.append(moreTemplateFunction(result));
               }
            } else if (handleErrors && result.message) {
               // Note! Potential "previous" hits still remains... [No $hitList.empty() here...]
               $errorDiv.empty().append(errorTemplateFunction(result));
            }
         }
      });
   });

   // User presses "Add as contact" button...
   $hitList.on('click', '[data-fn-add-contact]', function() {
      var $this = $(this),
         contact = $this.data('contact-id'),
         addURL = addContactBase + contact;

      $this.attr('disabled', 'disabled'); // Disable button ASAP

      ajax.doGet( {
         dataType: 'json',
         url: addURL,
         success: function(result) {
            var selector;
            if (result.added) {
               selector = '[data-contact-id="' + result.added + '"]';
               $(selector).closest('.sv-function-container').html(entryConnectedTemplateFunction(result));
            }
         }
      });
   });

});
