/**
 * Copyright (C) SiteVision AB 2002-2020, all rights reserved
 *
 * @author Karl Eklöf
 */

import sv from '@sv/core';
import $ from '@sv/jquery';
import _ from '@sv/underscore';
import {getModelObjectUri} from '../../../util/portletUtil';
import {
   KeyUtil as keyUtil,
   i18n as _i18n,
} from '@sv/util';

let KEY = keyUtil.KEY,
   i18cache = {},
   searchTimer,
   defaults = {
      success: function() {},
      selected: function() {},
      placeholder: '',
      markAdmins: false,
      hitTemplate: _.template('<li data-user-identity="<%= id %>" class="sv-clearfix">'+
                              '   <img class="sv-contact-img sv-buddy-icon" src="<%= iconURL %>" />'+
                              '   <% if ((typeof(isMember) !== \'undefined\' && isMember) || (typeof(isAdmin) !== \'undefined\' && isAdmin) || (typeof(isContact) !== \'undefined\' && isContact)) { %><div style="float:right"><i class="halflings-icon ok"></i></div><% } %>'+
                              '   <div class="sv-user-info-container">'+
                              '     <h3 class="sv-contact-name"><%= name %></h3>'+
                              '     <p>'+
                              '     <% _.each(userfields, function(field) { %>'+
                              '        <%= field.value %><br/>'+
                              '     <% }); %>'+
                              '     </p>'+
                              '   </div>'+
                              '</li>'),

      adminHitTemplate: _.template('<li data-user-identity="<%= id %>" class="sv-clearfix">'+
                              '   <img class="sv-contact-img sv-buddy-icon" src="<%= iconURL %>" />'+
                              '   <% if ((typeof(isAdmin) !== \'undefined\' && isAdmin)) { %><div style="float:right"><i class="halflings-icon ok"></i></div><% } %>'+
                              '   <div class="sv-user-info-container">'+
                              '     <h3 class="sv-contact-name"><%= name %></h3>'+
                              '     <p>'+
                              '     <% _.each(userfields, function(field) { %>'+
                              '        <%= field.value %><br/>'+
                              '     <% }); %>'+
                              '     </p>'+
                              '   </div>'+
                              '</li>')
   };

const i18n = function(selector, args) {
   var translated;

   if (!args && i18cache[selector]) {
      return i18cache[selector];
   }

   translated = _i18n.getText('portlet.social.util.searchuserutil', selector, args);

   if (!args) {
      i18cache[selector] = translated;
   }
   return translated;
};

export const showSearchPopover = function(anOptions) {
   var options = $.extend({}, defaults, anOptions),
      $target = $(options.target),
      active = 0;

   if ($target.hasClass('sv-fn-popover-showing')) {
      $('.popover').remove();
      $target.removeClass('sv-fn-popover-showing');
      return;
   }

   $('.popover').remove();
   $target.addClass('sv-fn-popover-showing')
      .popover({
            html: true,
            container: $target.closest('.modal').length > 0 ? $target.closest('.modal') : false,
            title: '<input type="text" placeholder="' +(options.placeholder || '') +'" data-fn-user-search class="input-medium search-query"/>',
            placement: 'bottom',
            content: '<ul class="sv-user-search-list sv-defaultlist"></ul>',
            trigger: 'manual'
      });

   $target.popover('show');

   // close if click outside popover
   var closeFunction = function(e) {
      if (!$(e.target).is('.popover *')) {
         $('.popover').remove();
         $target.removeClass('sv-fn-popover-showing');
         $('body').off('click', closeFunction);
      }
   };
   $('body').on('click', closeFunction);

   $('[data-fn-user-search]')
         .trigger('focus')
         .closest('.popover').addClass('sv-usersearch-popover');

   var source = getModelObjectUri(sv.PageContext.pageId, 'userIdentitySearch', sv.PageContext.pageId) +'?userfields=true',
      resultDiv = $('.popover-content').find('ul'),
      result = [];

   if (options.group) {
      source += '&group=' +options.group;
   }

   if (options.userIdentity) {
      source += '&identity=' +options.identity;
   }

   // set max height
   resultDiv.css('max-height', ((window.innerHeight - resultDiv.offset().top)-10) +'px');

   $('[data-fn-user-search]').on('keydown', function(e) {
      var keyCode = keyUtil.getKeyCodeFromEvent(e);

      if (keyCode === KEY.DOWN) {
         active++;
         if ((options.searchUrl && active > result.length) || (!options.searchUrl && active > result.length-1)) {
            active = 0;
         }

         resultDiv.children()
            .removeClass('active')
            .eq(active).addClass('active');

         return false;
      } else if (keyCode === KEY.UP) {
         active--;
         if (active < 0) {
            active = options.searchUrl ? result.length :result.length-1;
         }

         resultDiv.children()
            .removeClass('active')
            .eq(active).addClass('active');

         return false;
      }
   }).on('keyup', function(e) {
      var keyCode = keyUtil.getKeyCodeFromEvent(e);

      if (keyCode === KEY.ESC) { // esc close popover
         $('.popover').remove();
         $target.removeClass('sv-fn-popover-showing');
         return false;
      } else if (keyCode === KEY.RETURN) {
         if (active === result.length) {
            // "show more" link selected or no hits?
            if (active > 0 && typeof(resultDiv.children().eq(active).find('a').attr('href')) !== 'undefined') {
               location.href = resultDiv.children().eq(active).find('a').attr('href');
            } else if ($(this).val() !== '') {
               location.href = options.searchUrl +'&query='+ $(this).val();
            }
            return false;
         } else if (typeof (result[active]) !== 'undefined') {
            $('.popover').remove();
            $target.removeClass('sv-fn-popover-showing');
            options.selected && options.selected.call(this, result[active]);
            return false;
         }
      } else if (keyUtil.isNonTextModifiableKeyCode(keyCode)) {
         return false;
      } else {
         var that = this;
         searchTimer && clearTimeout(searchTimer);
         searchTimer = setTimeout(function() {
            // reset result
            active = 0;
            resultDiv.empty();
            // do new search
            var term = $(that).val();
            if (term !== '') {
               $.ajax({
                  url: source,
                  dataType: 'json',
                  data: {
                     term: term
                  }
               }).done(function(aResult) {
                  // Ensure result is still valid...
                  if (aResult.queryString === $(that).val()) {
                     active = 0;
                     resultDiv.empty();
                     result = aResult.hits;
                     $.each(result, function(index, value) {
                        var hit = options.markAdmins ? $(options.adminHitTemplate(value)) : $(options.hitTemplate(value));
                        resultDiv.append(hit);
                        hit.on('click', function() {
                           $('.popover').remove();
                           $target.removeClass('sv-fn-popover-showing');
                           options.selected && options.selected.call(this, value);
                           return false;
                        }).on('mouseenter', function() {
                           resultDiv.children().removeClass('active');
                           active = index;
                           $(this).addClass('active');
                        });
                     });
                     if (options.searchUrl && result.length > 0) {
                        var showMoreText = i18n('showAll');
                        if (result.length < 10) {
                           showMoreText = i18n('showAsList');
                        }

                        var showMore = $('<li class="sv-showmore"><a href="' +options.searchUrl +'&query=' +term +'">' +showMoreText +'</a></li>');
                        showMore.appendTo(resultDiv).on('mouseenter', function() {
                           resultDiv.children().removeClass('active');
                           active = result.length;
                        }).on('click', function() {
                           location.href = $(this).find('a').attr('href');
                           return false;
                        });
                        active = result.length;
                     }
                     resultDiv.children().eq(active).addClass('active');

                     options.success && options.success.call(this, result);
                  }
               });
            }
         }, 200);
      }
   });
};

sv.SearchUserUtil = {
   showSearchPopover: showSearchPopover
};
