/**
 * Copyright (C) SiteVision AB 2002-2020, all rights reserved
 *
 * @author Karl Eklöf
 */
import Backbone from '@sv/backbone';
import $ from '@sv/jquery';
import {showSearchPopover} from '../util/SearchUserUtil';
import {
  ObjectUtil as objectUtil,
  Events as events,
  i18n as _i18n,
} from '@sv/util';
import {
  getPortletResourceUri,
  getTemplate
} from '../../util/portletUtil';

const i18cache = {};

const i18n = function(selector, args) {
   var translated;

   if (!args && i18cache[selector]) {
      return i18cache[selector];
   }

   translated = _i18n.getText('portlet.social.groupadminall.groupadminall', selector, args);

   if (!args) {
      i18cache[selector] = translated;
   }
   return translated;
};

const SocialIdentity = Backbone.Model.extend({
   url: function() {
      var memberId = this.get('id');
      if (this.collection) {
         return this.collection.url() + '&member=' + memberId;
      } else {
         return '?member=' + memberId;
      }
   }
});

const GroupMemberCollection = Backbone.Collection.extend({
   model: SocialIdentity,

   initialize: function(models, options) {
      this.portletId = options.portletId;
   },

   url: function() {
      return getPortletResourceUri(this.portletId, 'groupMembers');
   }
});

const GroupItemView = Backbone.View.extend({
   tagName: 'li',

   className: 'sv-groupadmin-member',

   initialize: function () {
      this.template = this.options.template;
   },

   // Render HTML for group
   render: function() {
      this.$el.html(this.template(this.model.toJSON(), this.model));
      return this;
   }
});

const GroupMemberCollectionView = Backbone.View.extend({
   tagName: 'ol',

   events: {
      'click .sv-fn-groupadmin-add-member': 'addNewMember'
   },

   initialize: function () {
      this.memberTemplate = this.options.memberTemplate;
      this.list = this.options.list;
      this.searchUrl = this.options.searchUrl;
      this.groupId = this.options.groupId;
      this.collection.on('add', this.prependOne, this);
   },

   addNewMember: function (e) {
      var that = this;

      showSearchPopover({
         target: e.currentTarget,
         searchUrl: that.searchUrl,
         group: that.groupId,
         placeholder: i18n('searchUser'),
         selected: function(value) {
            var member = new SocialIdentity(value);
            that.collection.create(member, {
               wait: true,
               success: function() {
                  // add members
                  events.trigger(events.types.notifyUser, {
                     'type'      : 'success',
                     'heading'   : i18n('successAddMessageTitle'),
                     'message'   : i18n('successAddMessage', value.name),
                     'transient' : true,
                     'timer'     : 5
                  });
                     // TODO Update member count
               }
            });
         }
      });

      return false;
   },

   render: function() {
      this.clearList();

      this.collection.each(function(item) {
         this.appendOne(item);
      }, this);
   },

   appendOne: function(item) {
      var memberView = new GroupItemView({
         model: item,
         template: this.memberTemplate
      });

      this.list.append(memberView.render().el);
   },

   prependOne: function(item) {
      var memberView = new GroupItemView({
         model: item,
         template: this.memberTemplate
      });
      this.list.prepend(memberView.render().el);
   },

   clearList: function() {
      this.list.empty();
   }
});

$('.sv-groupadmin-portlet').each(function() {
   var portletDiv = $(this),
      portletId = objectUtil.getObjectId(portletDiv.attr('id')),
      memberList = portletDiv.find('ol.sv-groupmember-list'),
      searchUrl = memberList.data('search-url'),
      groupId = memberList.data('group'),
      jsMemberNamespace = memberList.data('js-namespace'),
      template = getTemplate(portletDiv,'groupadmin-listitem'),
      members = window.sv[jsMemberNamespace];

   const memberCollection = new GroupMemberCollection(members, {
      portletId: portletId
   });

   const memberCollectionView = new GroupMemberCollectionView({
      el: portletDiv,
      collection: memberCollection,
      memberTemplate: template,
      portletId: portletId,
      searchUrl: searchUrl,
      groupId: groupId,
      list: memberList
   });

   memberCollectionView.render();
});