/**
 * Swedish translation for bootstrap-datepicker
 * Patrik Ragnarsson <patrik@starkast.net>
 */
;(function($){
	$.fn.bootstrapDatepicker.dates['sv'] = {
		days: ["söndag", "måndag", "tisdag", "onsdag", "torsdag", "fredag", "lördag"],
		daysShort: ["sön", "mån", "tis", "ons", "tor", "fre", "lör"],
		daysMin: ["sö", "må", "ti", "on", "to", "fr", "lö"],
		months: ["januari", "februari", "mars", "april", "maj", "juni", "juli", "augusti", "september", "oktober", "november", "december"],
		monthsShort: ["jan", "feb", "mar", "apr", "maj", "jun", "jul", "aug", "sep", "okt", "nov", "dec"],
		today: "Idag",
		format: "yyyy-mm-dd",
		weekStart: 1,
		clear: "Rensa"
	};
}(window.$svjq));
