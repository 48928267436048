/**
 * Copyright (C) SiteVision AB 2002-2020, all rights reserved
 *
 */

import Backbone from '@sv/backbone';
import { i18nTimeline } from '../utils';
import { getPortletResourceUri } from '../../../util/portletUtil';

var Comment = Backbone.Model.extend({
  defaults: {
    comment: '',
    likes: 0,
    date: 0,
    author: {
      fullName: '',
      buddyIconURL: '/sitevision/core/profile_32.png',
    },
    removeTitle: i18nTimeline('removeCommentTitle'),
    editTitle: i18nTimeline('editCommentTitle'),
    removeReplyTitle: i18nTimeline('removeReplyTitle'),
    editReplyTitle: i18nTimeline('editReplyTitle'),
    replyLabel: i18nTimeline('replyLabel'),
  },

  url: function () {
    if (this.collection) {
      return (
        getPortletResourceUri(this.collection.portletId, 'plainComment') +
        '&entry=' +
        this.collection.id +
        '&commentId=' +
        this.get('id')
      );
    }

    return '?commentId=' + this.get('id');
  },
});

export default Comment;
