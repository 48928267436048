/*jshint evil:true*/ // eval has to be used when parsing 'data-alternatives' from data attributes since it contains single quotes
/**
 * Copyright (C) SiteVision AB 2002-2020, all rights reserved
 *
 * @author Karl Eklöf
 */

import sv from '@sv/core';
import $ from '@sv/jquery';
import { doGetWithFade, getPortletResourceUri } from '../../util/portletUtil';
import {
  Ajax as ajax,
  Events as events,
  ObjectUtil as objectUtil,
  DialogUtil as dialogUtil,
  ErrorUtil as errorUtil,
  i18n as _i18n,
} from '@sv/util';

const addShowMoreLink = function (obj) {
    $('<span class="sv-truncate-ellipsis">...</span>').insertBefore(obj);
    obj.css('display', 'none');
  },
  reloadPortlet = function ($portlet) {
    doGetWithFade(
      $portlet,
      '/' +
        sv.PageContext.pageId +
        '/' +
        objectUtil.getObjectId($portlet.attr('id')) +
        '.htm',
      function () {
        events.trigger(events.types.updateRelativeDates, $portlet);
      }
    );
  },
  i18n = function (key, args) {
    return _i18n.getText('portlet.social.bookmarklist.bookmarklist', key, args);
  };
/*      bookmarkTemplate = _.template('<li class="sv-clearfix sv-bookmarked-entry" style="margin-bottom:<%= margin %>">'+
                                 '   <% if (isAdmin) { %>'+
                                 '   <a class="close sv-remove sv-remove-entry" data-fn-destroy-entry data-entry="<%= id %>" title="' +i18n('deleteBookmark') +'" href="#">&times;</a>'+
                                 '   <% } %>'+
                                 '   <img class="sv-entry-img sv-buddy-icon" src="<%= buddyIconURL %>" alt="" />'+
                                 '   <a href="<%= bookmarkURL %>" class="sv-timeline-entry-link">'+
                                 '      <div class="sv-message-container">'+
                                 '         <% if (authorProfilePageURL) { %>'+
                                 '            <a class="<%= userFontClass %>" href="<%= authorProfilePageURL %>" data-user-identity="<%= author.id %>" data-fn-show-user-popup title="<%= author.fullName %>"><%= author.fullName %></a>'+
                                 '         <% } else { %>'+
                                 '            <span class="<%= userFontClass %>"><%= author.fullName %></span>'+
                                 '         <% } %>'+
                                 '         <span class="sv-entry-message <%= entryFontClass %>"><%= text %></span>'+
                                 '         <div class="sv-message-info <%= infoFontClass %>"><i class="halflings-icon time"></i><a href=""<%= authorProfilePageURL %>"><time data-fn-relative-date data-short="true" datetime="<%= bookmarkedEntry.date %>"><%= bookmarkedEntry.date %></time></a></div>'+
                                 '      </div>'+
                                 '   </a>'+
                                 '</li>');
*/

$('.sv-bookmarklist-portlet').each(function () {
  var $portlet = $(this),
    $bookmarkList = $portlet.find('ol'),
    owner = $bookmarkList.data('owner');

  events.on('server-' + $bookmarkList.data('channel'), function () {
    reloadPortlet($portlet);
  });

  var truncate = $portlet.find('.sv-truncate-more');

  if (truncate.length > 0) {
    addShowMoreLink(truncate);
  }

  $portlet.on('click', 'li', function (e) {
    var elem = $(e.currentTarget),
      entryUrl = elem.data('entry-url');

    location.href = entryUrl;
  });

  $portlet.on('click', '.close', function (e) {
    var elem = $(e.currentTarget),
      entryId = elem.data('entry');

    dialogUtil.showConfirmDialog(
      i18n('deleteTitle'),
      i18n('deleteMsg'),
      function (success) {
        if (success) {
          ajax
            .doDelete({
              url:
                getPortletResourceUri(
                  objectUtil.getObjectId($portlet.attr('id')),
                  'bookmark'
                ) +
                '&entry=' +
                entryId +
                '&owner=' +
                owner,
            })
            .done(function () {
              if (elem.closest('ol').find('li').length === 1) {
                reloadPortlet($portlet);
              } else {
                elem.closest('li').remove();
              }
            })
            .fail(function (response) {
              errorUtil.handleAjaxFailure(response);
            });
        }
      }
    );

    return false;
  });
});
