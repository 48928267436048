/*
* Copyright (C) SiteVision AB 2002-2020, all rights reserved
*
* @author robin
*/
import sv from '@sv/core';
import $ from '@sv/jquery';
import {
   DialogUtil as dialogUtil,
   i18n as _i18n,
} from '@sv/util';

const
   i18nCommon = function(key, args) {
      return _i18n.getText('common', key, args);
   },
   i18cache = {};

// HELPERS ----------------------------------------------------
const i18n = function(selector, args) {
   let translated;

   if (!args && i18cache[selector]) {
      return i18cache[selector];
   }

   translated = _i18n.getText('portlet.social.profileprogress.profileprogress', selector, args);

   if (!args) {
      i18cache[selector] = translated;
   }
   return translated;
},
createModalContent = function(portletSettings) {

   var buttons = [{
      text: i18nCommon('ok'),
      primary: true
   }];

   var $modalContent = $('<div/>'),
      $dl;

   if (portletSettings.descriptiveText) {
      $modalContent.append('<p>' + portletSettings.descriptiveText + '</p>');
   }

   var profileCriteria = $.extend(portletSettings.profileCriteria, portletSettings.fields);

   if (profileCriteria && !$.isEmptyObject(profileCriteria)) {
      $modalContent.append('<h3>'+ i18n('profileCriteria') +'</h3>');
      $dl = $('<dl/>');
      $.each(profileCriteria, function(k,v) {
         if (v) {
            $dl.append('<dt class="sv-has-value"><span class="halflings-icon ok"></span> ' + i18n(k) + '</dt>');
         } else {
            $dl.append('<dt class="sv-no-value"><span class="halflings-icon remove sv-no-value"></span> ' + i18n(k) + '</dt>');
            var text = k === 'hasProfileImage' ? i18n('incomplete') : i18n('missingValue');
            $dl.append('<dd>'+ text +'</dd>');
         }
      });
      $modalContent.append($dl);
   }

   if (portletSettings.collaborationCriteria && !$.isEmptyObject(portletSettings.collaborationCriteria)) {
      $modalContent.append('<h3>'+ i18n('collaborationCriteria') +'</h3>');
      $dl = $('<dl/>');
      $.each(portletSettings.collaborationCriteria, function(k,v) {
         if (v) {
            $dl.append('<dt class="sv-has-value"><span class="halflings-icon ok"></span> ' + i18n(k) + '</dt>');
         } else {
            $dl.append('<dt class="sv-no-value"><span class="halflings-icon remove sv-no-value"></span> ' + i18n(k) + '</dt>');
            $dl.append('<dd>'+ i18n('incomplete') +'</dd>');
         }
      });
      $modalContent.append($dl);
   }

   if (portletSettings.helpPageUrl) {
      $modalContent.append('<a href="'+ portletSettings.helpPageUrl +'">'+i18n('helpPage')+'</a>');
   }

   $('dl', $modalContent).addClass('sv-clearfix env-m-around--0');
   $('dd', $modalContent).addClass('sv-float-left');
   $('dt', $modalContent).addClass('sv-float-left').addClass('sv-clear-left');
   $('h3', $modalContent).addClass('sv-clear-left');

   var content = {
      content: $modalContent,
      buttons: buttons
   };

   return content;
};

$('.sv-profileprogress-portlet').on('click','.sv-profile-progress', function() {
   var $this = $(this);
   if ($this.parent().is('[data-portlet-type]')) {
      return false; //Dont show popup in edit mode
   }

   var jsNamespace = $this.data('js-namespace'),
      portletSettings = sv[jsNamespace],
      modalContent = createModalContent(portletSettings);

   dialogUtil.showDialog({
      title: i18n('profileProgress'),
      body: modalContent.content,
      buttons: modalContent.buttons,
      removeOnHide: true
   });
});