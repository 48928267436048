/*
 * Copyright (C) SiteVision AB 2002-2020, all rights reserved
 *
 */
import sv from '@sv/core';
import _ from '@sv/underscore';
import $ from '@sv/jquery';
import Backbone from '@sv/backbone';
import { getPortletResourceUri } from '../../../util/portletUtil';
import { Events as events, Log as log } from '@sv/util';
import TimelineEntry from '../model/TimelineEntry';

const readTimeoutInMS = sv.PageContext.userIdentityReadTimeout * 1000;
const Timeline = Backbone.Collection.extend({
  model: TimelineEntry,

  page: 0,

  initialize: function (models, options) {
    this.id = options.id;
    this.portletId = options.portletId;
    this.addSharedEntries = options.addSharedEntries;
    this.bindServerEvents(options.timelineChannel);
    this.isCompoundTimeline = options.isCompoundTimeline;
    this.isPersonalTimeline = options.isPersonalTimeline;
    this.isTagTimeline = options.isTagTimeline;
    this.userIdentity = options.userIdentity;
    this.isGroupAdmin = options.isGroupAdmin;
    this.isGroupPage = options.isGroupPage;
    this.isUserIdentityDisabled = options.isUserIdentityDisabled;
    this.moment = options.moment;

    this.activeSession = true;

    this.updateRead();

    this.on('add', this.handleEntryAdded, this);
  },

  bindServerEvents: function (channel) {
    events.on('server-' + channel, (message) => {
      var messageType = message.type,
        messageContent = message.content,
        entry;

      if (messageType === 'entry') {
        var author = messageContent.author,
          isCreator = author.id === this.userIdentity,
          isEntryOwner = messageContent.entryOwnerId === this.userIdentity,
          isAdmin =
            this.isGroupAdmin || _.contains(author.admins, this.userIdentity);

        messageContent.markAsUnread = !(isCreator || isEntryOwner);

        if (this.isUserIdentityDisabled) {
          messageContent.isRemovable = false;
          messageContent.isLikable = false;
          messageContent.isCommentable = false;
        } else {
          if (this.isCompoundTimeline) {
            messageContent.isRemovable = true;
            messageContent.isEditable = isCreator;
          } else if (this.isPersonalTimeline) {
            messageContent.isRemovable = isCreator || isEntryOwner || isAdmin;
            messageContent.isEditable = isCreator;
          } else if (this.isGroupPage) {
            messageContent.isRemovable = isCreator || isAdmin;
            messageContent.isEditable = isCreator || isEntryOwner || isAdmin;
          } else if (isCreator) {
            messageContent.isRemovable = true;
            messageContent.isEditable = true;
          } else {
            messageContent.isRemovable = false;
          }

          messageContent.isLikable = true;
          messageContent.isCommentable = true;
          messageContent.isBookmarkable = true;
        }

        this.add(messageContent, {
          append: false,
        });
      } else if (messageType === 'comment') {
        if (this.isUserIdentityDisabled) {
          messageContent.comment.isRemovable = false;
          messageContent.comment.isEditable = false;
        } else {
          if (messageContent.comment.author.id === this.userIdentity) {
            messageContent.comment.isRemovable = true;
            messageContent.comment.isEditable = true;
          } else if (this.isGroupPage && this.isGroupAdmin) {
            messageContent.comment.isRemovable = true;
            messageContent.comment.isEditable = false;
          } else {
            messageContent.comment.isRemovable = false;
            messageContent.comment.isEditable = false;
          }
        }

        messageContent.comment.isLikable = true;
        entry = this.get(messageContent.entryId);

        if (entry) {
          var comment = messageContent.comment;
          if (comment.isSubComment) {
            entry.trigger('update:sub:comments', messageContent);
          } else {
            entry.trigger('update:comments', messageContent);
          }
        }
      } else if (messageType === 'like') {
        entry = this.get(messageContent.entryId);
        if (entry) {
          entry.trigger('update:likes', messageContent);
        }
      } else {
        log.warn(null, 'message.type ' + messageType + ' not supported');
      }
    });
  },

  handleEntryAdded: function (entry, collection, options) {
    if (this.isGroupPage) {
      return; // handled globally on the page. See groupPage.js
    }
    if (options.add) {
      window.setTimeout(
        $.proxy(function () {
          this.markEntryAsRead(entry).done(function () {
            entry.trigger('markEntryAsRead');
          });
        }, this),
        readTimeoutInMS
      );
    }
  },

  updateRead: function () {
    if (this.isGroupPage) {
      return; // handled globally on the page. See groupPage.js
    }
    window.setTimeout(
      $.proxy(function () {
        var latestEntry = this.at(0);
        this.markEntryAsRead(latestEntry).done(
          $.proxy(function () {
            this.trigger('markAsRead');
          }, this)
        );
      }, this),
      readTimeoutInMS
    );
  },

  markEntryAsRead: function (entry) {
    if (this.activeSession && entry && entry.get('markAsUnread')) {
      return $.ajax({
        method: 'PUT',
        url:
          getPortletResourceUri(this.portletId, 'markAsRead') +
          '&latestEntry=' +
          entry.get('id'),
        context: this,
      }).fail(function (jqXHR) {
        switch (jqXHR.status) {
          case 401:
          case 403:
            this.activeSession = false;
            break;
        }
      });
    }

    var deferred = $.Deferred();
    deferred.resolve();
    return deferred.promise();
  },

  comparator: function (m1, m2) {
    m1 = this.moment(m1.get('date')).unix();
    m2 = this.moment(m2.get('date')).unix();

    if (m1 < m2) {
      return 1;
    } else if (m1 > m2) {
      return -1;
    }

    return 0;
  },

  url: function () {
    return (
      getPortletResourceUri(this.portletId, 'timelineEntry') +
      '&timelineOwner=' +
      this.id
    );
  },
});

export default Timeline;
