
(function($) {

   'use strict';

   var customWhiteList = $.fn.tooltip.Constructor.DEFAULTS.whiteList;

   customWhiteList.div = ['data-fn-group-search', 'style', 'data-sv-fn-show-more', 'data-sv-fn-loading', 'data-fn-drop-down'];
   customWhiteList.form = ['data-fn-group-search'];
   customWhiteList.input = ['data-fn-group-search', 'data-fn-user-search'];
   customWhiteList.a = ['target', 'href', 'title', 'rel', 'data-conversation', 'data-new-conversation'];
   customWhiteList.button = [];

}(window.$svjq));
