/**
 * Copyright (C) SiteVision AB 2002-2020, all rights reserved
 *
 */

import Backbone from '@sv/backbone';
import { getPortletResourceUri } from '../../../util/portletUtil';
import Comment from '../model/Comment';

const Comments = Backbone.Collection.extend({
  model: Comment,

  initialize: function (models, options) {
    this.id = options.id;
    this.portletId = options.portletId;
    this.isSubComment = options.isSubComment;
  },

  url: function () {
    return (
      getPortletResourceUri(this.portletId, 'plainComments') +
      '&entry=' +
      this.id
    );
  },

  parse: function (data) {
    this.isAllowedToComment = data.isAllowedToComment;
    this.trigger('updateCommentArea', this.isAllowedToComment);
    return data.comments;
  },
});

export default Comments;
