/**
 * Copyright (C) SiteVision AB 2002-2020, all rights reserved
 *
 * @author Karl Eklöf
 */
import $ from '@sv/jquery';
import {popupUserSettingsDialog} from '../util/userSettingsUtil';

$('.sv-userfields-portlet').on('click', '[data-fn-edit-userfields]', function() {
   var fields = $(this).attr('data-fields');
   popupUserSettingsDialog(fields);
});