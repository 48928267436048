/**
 * Copyright (C) SiteVision AB 2002-2020, all rights reserved
 *
 * listen to changes related to a group page
 *
 * @author micke
 */

import sv from '@sv/core';

import {
   Ajax as ajax,
   Events as events,
} from '@sv/util';

const pageContext = sv.PageContext;

let activeSession = true,
   readTimeoutInMS = pageContext.userIdentityReadTimeout * 1000,
   timeout,
   uuidQueue = ['0']; // a non empty array will trigger an initial groupViewed

function startTimeout(time) {
   timeout = window.setTimeout(function() {
      if (activeSession && uuidQueue.length > 0) {
         var uuid = uuidQueue[uuidQueue.length - 1],
            pageId = pageContext.pageId;
         uuidQueue = ['0'];
         ajax.doPut({
            url: '/modelobjectapi/1/' + pageId + '/' + pageId + '/groupViewed/' + pageId,
            data: JSON.stringify({
               'uuid': uuid
            })
         }).done(function() {
            events.trigger(events.types.groupPageViewed);
         }).fail(function(jqXHR) {
            switch (jqXHR.status) {
               case 401:
               case 403:
                  activeSession = false;
                  break;
            }
         });
      }
      timeout = undefined;
   }, time || readTimeoutInMS);
}

if (pageContext && pageContext.pageId && pageContext.pageId.match('^704\\.')) { // i.e is it a group page

   startTimeout();

   // NOTE: the name must correlate to PublishingService.CHANNEL_GROUP_UPDATED_BASE
   events.on('server-group-updated-' + pageContext.pageId, function(message) {
      uuidQueue.push(message.uuid);
      if (timeout) {
         window.setTimeout(function() {
            window.clearTimeout(timeout);
            startTimeout(readTimeoutInMS / 2);
         }, readTimeoutInMS / 2);
      } else {
         startTimeout();
      }
   });
}
