/**
 * Norwegian translation for bootstrap-datepicker
 * George Gooding <george@nettsentrisk.no>
 */
;(function($){
  $.fn.bootstrapDatepicker.dates['no'] = {
    days: ['søndag', 'mandag', 'tirsdag', 'onsdag', 'torsdag', 'fredag', 'lørdag'],
    daysShort: ['søn', 'man', 'tir', 'ons', 'tor', 'fre', 'lør'],
    daysMin: ['sø', 'ma', 'ti', 'on', 'to', 'fr', 'lø'],
    months: ['januar', 'februar', 'mars', 'april', 'mai', 'juni', 'juli', 'august', 'september', 'oktober', 'vovember', 'desember'],
    monthsShort: ['jan', 'feb', 'mar', 'apr', 'mai', 'jun', 'jul', 'aug', 'sep', 'okt', 'nov', 'des'],
    today: 'i dag',
    monthsTitle: 'Måneder',
    clear: 'Nullstill',
    weekStart: 1,
    format: 'dd.mm.yyyy'
  };
}(window.$svjq));
