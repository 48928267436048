/**
* Copyright (C) SiteVision AB 2002-2023, all rights reserved
*
* Handles relative dates, it looks for 'time' elements with the
* data attribute 'data-fn-relative-date'. The element has to provide a ISO8601
* formatted date in the datetime attribute.
*
* @author albin
*/
import $ from '@sv/jquery';
import _ from '@sv/underscore';
import {Events as events} from '@sv/util';
import moment from '../vendor/moment';

const lang = $('html').attr('lang') || 'en';

moment && moment.locale(lang);

var absoluteDate = function(elapsed, key) {
  try {
    if (key === 'd') {
      if (lang === 'sv' || lang === 'no') {
        return 'i går';
      } else if (lang === 'fi') {
        return 'eilen';
      }
      return 'yesterday';
    } else {
      return moment().subtract(elapsed, 'milliseconds').format('D MMM');
    }
  } catch (e) {
    return '';
  }
};

var bundle = {
  sv_short: {
    future : 'alldeles nyss',
    past : '%s',
    s : 'alldeles nyss',
    m : '1 min',
    mm : ' min',
    h : '1 tim',
    hh : ' tim',
    d : absoluteDate,
    dd : absoluteDate,
    M : absoluteDate,
    MM : absoluteDate,
    y : '1 år',
    yy : ' år'
  },
  sv: {
    future : 'alldeles nyss',
    past : 'för %s sedan',
    s : 'några sekunder',
    m : 'en minut',
    mm : ' minuter',
    h : 'en timme',
    hh : ' timmar',
    d : 'en dag',
    dd : ' dagar',
    M : 'en månad',
    MM : ' månader',
    y : 'ett år',
    yy : ' år'
  },
  no_short: {
    future : 'akkurat nå',
    past : '%s',
    s : 'akkurat nå',
    m : '1 min',
    mm : ' min',
    h : '1 tim',
    hh : ' tim',
    d : absoluteDate,
    dd : absoluteDate,
    M : absoluteDate,
    MM : absoluteDate,
    y : '1 år',
    yy : ' år'
  },
  no: {
    future : 'akkurat nå',
    past : 'for %s siden',
    s : 'akkurat nå',
    m : 'ett minutt',
    mm : ' minutter',
    h : 'en time',
    hh : ' timer',
    d : 'en dag',
    dd : ' dager',
    M : 'en måned',
    MM : ' måneder',
    y : 'ett år',
    yy : ' år'
  },
  en_short: {
    future : 'just now',
    past : '%s',
    s : 'just now',
    m : '1 mins',
    mm : ' mins',
    h : '1 hr',
    hh : ' hrs',
    d : absoluteDate,
    dd : absoluteDate,
    M : absoluteDate,
    MM : absoluteDate,
    y : 'a year',
    yy : ' years'
  },
  en: {
    future : 'just now',
    past : '%s ago',
    s : 'a few seconds',
    m : 'a minute',
    mm : ' minutes',
    h : 'an hour',
    hh : ' hours',
    d : 'a day',
    dd : ' days',
    M : 'a month',
    MM : ' months',
    y : 'a year',
    yy : ' years'
  },
  fi_short: {
    future : 'ihan äsken',
    past : ' %s',
    s : 'ihan äsken',
    m : '1 min',
    mm : ' min',
    h : '1 tunti',
    hh : ' tuntia',
    d : absoluteDate,
    dd : absoluteDate,
    M : absoluteDate,
    MM : absoluteDate,
    y : '1 vuosi',
    yy : ' vuotta'
  },
  fi: {
    future : 'ihan äsken',
    past : '%s sitten',
    s : 'ihan äsken',
    m : 'yksi minuutti',
    mm : ' minuuttia',
    h : 'yksi tunti',
    hh : ' tuntia',
    d : 'yksi päivä',
    dd : ' päivää',
    M : 'yksi kuukausi',
    MM : ' kuukautta',
    y : 'yksi vuosi',
    yy : ' vuotta'
  },
  pt_short: {
      future : 'agora mesmo',
      past : '%s',
      s : 'só agora',
      m : '1 min',
      mm : ' minutos',
      h : '1 hora',
      hh : ' horas',
      d : absoluteDate,
      dd : absoluteDate,
      M : absoluteDate,
      MM : absoluteDate,
      y : 'um ano',
      yy : ' anos'
    },
    pt: {
      future : 'agora mesmo',
      past : '%s atrás',
      s : 'alguns segundos',
      m : 'um minuto',
      mm : ' minutos',
      h : 'uma hora',
      hh : ' horas',
      d : 'um dia',
      dd : ' dias',
      M : 'um mês',
      MM : ' meses',
      y : 'um ano',
      yy : ' anos'
    },
};

var timeAgo = function(date, bundle) {
  var second = 1000;
  var minute = second * 60;
  var hour = minute * 60;
  var day = hour * 24;

  var thresholds = [
    {
      threshold: 540 * day,
      modifier: 365 * day,
      render: function(elapsed, humanReadable) {
        return bundle.past.replace(/%s/, humanReadable + bundle.yy);
      }
    },
    {
      threshold: 320 * day,
      render: function() {
        return bundle.past.replace(/%s/, bundle.y);
      }
    },
    {
      threshold: 45 * day,
      modifier: 30 * day,
      render: function(elapsed, humanReadable) {
        return _.isFunction(bundle.MM) ? bundle.MM(elapsed) : bundle.past.replace(/%s/, humanReadable + bundle.MM);
      }
    },
    {
      threshold: 26 * day,
      render: function(elapsed) {
        return _.isFunction(bundle.M) ? bundle.M(elapsed) : bundle.past.replace(/%s/, bundle.M);
      }
    },
    {
      threshold: 36 * hour,
      modifier: 24 * hour,
      render: function(elapsed, humanReadable) {
        return _.isFunction(bundle.dd) ? bundle.dd(elapsed) : bundle.past.replace(/%s/, humanReadable + bundle.dd);
      }
    },
    {
      threshold: 22 * hour,
      render: function() {
        return _.isFunction(bundle.d) ? bundle.d(elapsed, 'd') : bundle.past.replace(/%s/, bundle.d);
      }
    },
    {
      threshold: 90 * minute,
      modifier: 60 * minute,
      render: function(elapsed, humanReadable) {
        return bundle.past.replace(/%s/, humanReadable + bundle.hh);
      }
    },
    {
      threshold: 45 * minute,
      render: function() {
        return bundle.past.replace(/%s/, bundle.h);
      }
    },
    {
      threshold: 90 * second,
      modifier: 60 * second,
      render: function(elapsed, humanReadable) {
        return bundle.past.replace(/%s/, humanReadable + bundle.mm);
      }
    },
    {
      threshold: 46 * second,
      render: function() {
        return bundle.past.replace(/%s/, bundle.m);
      }
    },
    {
      threshold: 0 * second,
      render: function() {
        return bundle.past.replace(/%s/, bundle.s);
      }
    }
  ];

  var elapsed = Math.round(Date.now() - date.getTime());

  if (elapsed <= 0) {
    return bundle.future;
  }
  var threshold = _.find(thresholds, function(threshold) {
    return elapsed >= threshold.threshold;
  }),
  render = threshold.render,
  modifier = threshold.modifier;

  return render(Math.round(elapsed), modifier ? Math.round(elapsed / modifier) : undefined);
};

/**
* Update time elements that are supposed to show relative times
* @param $scope a jQuery elements in which elements will be updated.
* If no scope is provided body will be used. Try to avoid that...
*/
var updateRelativeDates = function($scope) {
  $scope = $scope || $('body');

  $scope.find('time[data-fn-relative-date]').each(function() {
    var $this = $(this),
    machineReadableTime = $this.attr('datetime');

    if (machineReadableTime) {
      var shortDate = $this.data('short'),
      language = shortDate ? lang + '_short' : lang,
      fallbackLanguage = shortDate ? 'en_short' : 'en',
      realBundle = bundle[language] || bundle[fallbackLanguage];

      $this.text(timeAgo(new Date(machineReadableTime), realBundle));
      $this.attr('title',  moment(machineReadableTime).format('LLLL'));
    }
  });
};

window.setInterval(updateRelativeDates, 60000);

events.on(events.types.updateRelativeDates, updateRelativeDates);

updateRelativeDates(); // trigger once when the page is loaded.