/**
* Copyright (C) SiteVision AB 2002-2020, all rights reserved
*
* @author sjostrand
*/

import _ from '@sv/underscore';
import $ from '@sv/jquery';
import {getPortletResourceUri} from '../../util/portletUtil';
import {
  ObjectUtil as objectUtil,
  Ajax as ajax,
  KeyUtil as keyUtil,
  i18n as _i18n,
} from '@sv/util';

const KEY = keyUtil.KEY,
  i18cache = {};

const defaults = {
  success: function() {},
  selected: function() {},
  placeholder: '',
  hitTemplate: _.template(
  '<li id="<%= hitId %>" aria-selected="false" role="option" data-fn-user-identity="<%= id %>" class="sv-clearfix">'+
  '   <img class="sv-contact-img sv-buddy-icon" src="<%= iconURL %>" />'+
  '   <% if (typeof(isContact) !== \'undefined\' && isContact) { %><div style="float:right"><i class="halflings-icon ok"></i></div><% } %>'+
  '   <div class="sv-social-contactsearch-user-container">'+
  '     <h3 class="sv-social-contactsearch-name <%= nameFont%>"><%= name %></h3>'+
  '     <ul class="sv-social-contactsearch-userfield">' +
  '     <% _.each(userfields, function(field) { %>'+
  '     <li class="sv-social-contactsearch-userfield <%= fieldFont%>">'+
  '        <%= field.value %>'+
  '     </li>'+
  '     <% }); %>'+
  '   </div>'+
  '</li>')
};
const i18n = function(selector, args) {
  var translated;

  if (!args && i18cache[selector]) {
    return i18cache[selector];
  }

  translated = _i18n.getText('portlet.social.contactsearch.contactsearch', selector, args);

  if (!args) {
    i18cache[selector] = translated;
  }
  return translated;
};

const showSearchResultPopup = function(anOptions) {
  const options = $.extend({}, defaults, anOptions),
    $target = $(options.target),
    $portlet = $target.closest('.sv-contactsearch-portlet'),
    maxHits = $target.data('fn-profile-page-max-items'),
    $resultContainer = $portlet.find('.sv-social-contactsearch-result'),
    portletId = anOptions.portletId;

  const $searchResultsCount = $portlet.find('.sv-search-results-count');
  // close if click outside popover
  const closeFunction = function(e) {
    if (e && !$(e.target).is('.sv-social-contactsearch-form *')) {
      $target.prop('sv-fn-has-selected', false);
      $resultContainer.hide();
    } else if (e === undefined) {
      $target.prop('sv-fn-has-selected', false);
      $resultContainer.hide();
    }
  };
  const resultList = $resultContainer.find('.sv-user-search-list');


  $('body').on('click', closeFunction);

  const addActive = function($activeElement) {
    resultList.children()
      .attr('aria-selected', false)
      .removeClass('active');

    $activeElement
      .addClass('active')
      .attr('aria-selected', true);

    $target.attr('aria-activedescendant', $activeElement.attr('id'));
  };

  let active,
   searchTimer,
   result = [];

  $target.on('keydown', function(e) {
    const keyCode = keyUtil.getKeyCodeFromEvent(e);
    let theSelected;

    if (keyCode === KEY.DOWN) {
      $target.attr('sv-fn-has-selected', true);
      active++;
      if ((options.searchUrl && active > result.length) || (!options.searchUrl && active > result.length-1)) {
        active = 0;
      }

      theSelected = resultList.children().eq(active);
      if (!theSelected.hasClass('sv-contactsearch-nohits')) {
        addActive(theSelected);
      }

      return false;
    } else if (keyCode === KEY.UP) {
      $target.attr('sv-fn-has-selected', true);
      active--;
      if (active < 0) {
        active = options.searchUrl ? result.length :result.length-1;
      }

      theSelected = resultList.children().eq(active);
      if (!theSelected.hasClass('sv-contactsearch-nohits')) {
        addActive(theSelected);
      }

      return false;
    }
  }).on('keyup', function(e) {
    const keyCode = keyUtil.getKeyCodeFromEvent(e);

    if (keyCode === KEY.ESC) {
      closeFunction();
    } else if (keyCode === KEY.RETURN) {
      if (active === result.length) {
        // show more
        location.href = resultList.children().eq(active).find('a').attr('href');
      } else if (typeof (result[active]) !== 'undefined') {
        //$target.popover('destroy');
        options.selected && options.selected.call(this, result[active]);
      }
    }

    return false;
  }).on('input', function() {
    const that = this,
      source = getPortletResourceUri(portletId, 'query');

    searchTimer && clearTimeout(searchTimer);
    searchTimer = setTimeout(function() {
      // reset result
      active = -1;
      resultList.empty();
      // do new search
      const term = $(that).val();
      if (term !== '') {
        ajax.doGet({
          url: source,
          data: {
            term: term
          }
        }).done(function(aResult) {
          $searchResultsCount.html('');
          $searchResultsCount.text(aResult.hits.length + ' ' + i18n('results'));
          // Ensure result is still valid...
          if (aResult.queryString === $(that).val()) {
            result = aResult.hits;

            // Make sure to hide other popovers
            $('.popover').remove();

            $resultContainer.removeClass('env-d--none')
            $resultContainer.show();
            $resultContainer.position({
              my: 'left top',
              at: 'left bottom',
              of: $target,
              offset: '0 1',
              collision: 'fit'
            });
            active = -1;
            resultList.empty();
            $.each(result, function(index, value) {
              const nameFont = $target.data('fn-namefontclass'),
                fieldFont = $target.data('fn-fieldfontclass'),
                hitId = $target.attr('id') + '_hit_' + index;

              $.extend(value, {nameFont: nameFont, fieldFont: fieldFont, hitId: hitId});
              const hit = $(options.hitTemplate(value));

              resultList.append(hit);
              hit.on('click', function() {
                options.selected && options.selected.call(that, value);
                return false;
              }).on('mouseenter', function() {
                resultList.children().removeClass('active');
                active = index;
                const theSelected = resultList.children().eq(active);

                addActive(theSelected);
              });
            });

            if (options.searchUrl && result.length > 0) {
              let showMore,
                theSearchUrl = options.searchUrl +'?query=' +term,
                showMoreId = $target.attr('id') + '_show_more',
                showMoreString = result.length === maxHits ? i18n('showAll') : i18n('showList');

              if (options.selectedIdentity) {
                theSearchUrl = theSearchUrl + '&identity=' + options.selectedIdentity;
              }

              showMore = $('<li id="' + showMoreId + '" role="option" class="sv-showmore">' +
              '<a class="'+$target.data('fn-fieldfontclass')+'" href="' + theSearchUrl +'">' + showMoreString +'</a></li>');

              showMore.on('focus', function() {
                showMore.attr('aria-selected', true);
              });

              showMore.on('click', function() {
                location.href = theSearchUrl;
              });
              showMore.appendTo(resultList).on('mouseenter', function() {
                resultList.children().removeClass('active');
                active = result.length;

                $(this).addClass('active');
              });
            } else if (result.length === 0) {
              const noHits = $('<li class="sv-contactsearch-nohits"><span class="' + $target.data('fn-fieldfontclass') + '">' + i18n('noHits') + '</span></li>');
              resultList.append(noHits);

              noHits.appendTo(resultList).on('mouseenter', function() {
                resultList.children().removeClass('active');
              });
            }

            options.success && options.success.call(that, result);
          }
        });
      } else {
        closeFunction();
      }
    }, 200);

    return false;
  });
};

$('[data-fn-contactsearch]').each(function() {
  const portletDiv = $('.sv-contactsearch-portlet'),
    portletId = objectUtil.getObjectId(portletDiv.attr('id')),
    searchBox = $(this),
    resultPageURL = searchBox.data('fn-result-page-url'),
    profilePageURL = searchBox.data('fn-profile-page-url'),
    selectedIdentity = searchBox.data('selected-identity');

  searchBox.closest('form').on('submit', function(e) {
    if (!searchBox.is('[sv-fn-has-selected]')) {
      e.preventDefault();
      location.href=resultPageURL+'?query='+searchBox.val();
    } else {
      e.preventDefault();
    }
  });
  showSearchResultPopup({
    'target':searchBox,
    'profilePageURL':profilePageURL,
    'searchUrl':resultPageURL,
    'portletId': portletId,
    'selectedIdentity': selectedIdentity,
    'selected': function(args) {
      var id = $(this).data('fn-user-identity');
      if (id === undefined) {
        id = args.id;
      }
      var url = profilePageURL + '?identity='+id;
      location.href = url;
    }
  });
});
