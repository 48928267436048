import moment from 'moment';

window.moment = moment;

import enGb from './en-gb';
import enShort from './en-short';
import fi from './fi';
import fiShort from './fi-short';
import no from './no';
import noShort from './no-short';
import sv from './sv';
import svShort from './sv-short';

moment.locale('en-gb', enGb);
moment.locale('en-short', enShort);
moment.locale('fi', fi);
moment.locale('fiShort', fiShort);
moment.locale('no', no);
moment.locale('noShort', noShort);
moment.locale('sv', sv);
moment.locale('svShort', svShort);

export default moment;