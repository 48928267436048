/*jshint nonew:false*/
/**
 * Copyright (C) SiteVision AB 2002-2020, all rights reserved
 *
 * @author albin
 *
 * Handles listing and filtering of a Social Identities all contacts.
 * Works alongside with usercontactall.css and usercontactsall.vm
 */

import sv from '@sv/core';
import $ from '@sv/jquery';
import Backbone from '@sv/backbone';
import {getPortletResourceUri, getTemplate} from '../../util/portletUtil';
import {
  Events as events,
  ErrorUtil as errorUtil,
  ObjectUtil as objectUtil,
  ClientUtil as clientUtil,
  i18n as _i18n,
} from '@sv/util';

const i18cache = {};

var i18n = function (selector, args) {
  var translated;

  if (!args && i18cache[selector]) {
    return i18cache[selector];
  }

  translated = _i18n.getText(
    'portlet.social.usercontactsall.usercontactsall',
    selector,
    args
  );

  if (!args) {
    i18cache[selector] = translated;
  }
  return translated;
};

var SocialContact = Backbone.Model.extend({
  defaults: {
    fullName: '',
    profileImageURL: '',
    profilePageURL: '/',
    userFields: [],
  },

  url: function () {
    return this.collection.url() + '&contact=' + this.get('id');
  },

  getProfilePageUrl: function () {
    return this.get('showProfileURL');
  },
});

var SocialContacts = Backbone.Collection.extend({
  model: SocialContact,

  initialize: function (models, options) {
    this.id = options.id;
    this.portletId = options.portletId;
  },

  url: function () {
    return getPortletResourceUri(this.portletId, 'contact');
  },

  comparator: function (modelObject) {
    if (modelObject.get('fullName') !== null) {
      return modelObject.get('fullName').toLowerCase();
    } else {
      return null;
    }
  },
});

var SocialContactView = Backbone.View.extend({
  tagName: 'li',

  className: 'sv-clearfix sv-social-contact',

  events: {
    'click [data-fn-destroy]': 'destroy',
    click: 'navigate',
  },

  initialize: function () {
    this.bindModelEvents();
  },

  bindModelEvents: function () {
    this.model.on('filter', this.toggleVisible, this);
    this.model.on('destroy', this.remove, this);
  },

  destroy: function () {
    this.model.destroy({
      wait: true,
      error: function (model, xhr, options) {
        errorUtil.handleBackboneError(model, xhr, options);
      },
    });
    return false;
  },

  navigate: function (e) {
    if (
      (clientUtil.isMac && e.metaKey) ||
      (!clientUtil.isMac && e.ctrlKey) ||
      e.shiftKey
    ) {
      window.open(this.model.getProfilePageUrl());
    } else {
      window.location.href = this.model.getProfilePageUrl();
    }
    return;
  },

  toggleVisible: function (opts) {
    this.$el.toggleClass('env-d--none', !this.isVisible(opts.filter));
  },

  isVisible: function (filter) {
    var contact =
      this.model.get('fullName') + this.model.get('userFields').join('|');
    return (
      !filter ||
      (contact && contact.toLowerCase().indexOf(filter.toLowerCase()) !== -1)
    );
  },

  remove: function () {
    var that = this;
    this.$el.fadeOut('3000', function () {
      that.$el.remove();
      events.trigger(events.types.notifyUser, {
        type: 'success',
        message: i18n('successMessage', that.model.get('fullName')),
        transient: true,
        timer: 5,
      });
    });
  },

  render: function () {
    this.$el.html(this.options.template(this.model.toJSON()));
    return this;
  },
});

var SocialContactsView = Backbone.View.extend({
  events: {
    'click [data-fn-switch-list-style]': 'setListStyle',
    'keyup [data-fn-filter]': 'setFilter',
    'change [data-fn-filter]': 'setFilter',
    'blur [data-fn-filter]': 'setFilter',
  },

  initialize: function () {
    this.$list = this.$el.find('ol');
    this.listStyle = this.options.listStyle;

    if (this.collection.length > 0) {
      this.render();
    }
  },

  render: function () {
    this.clearList();

    this.collection.each(function (item) {
      this.appendOne(item);
    }, this);
  },

  appendOne: function (socialContact) {
    var view = new SocialContactView({
      model: socialContact,
      template: this.options.socialContactTemplate,
    });
    this.$list.append(view.render().el);
  },

  filterOne: function (socialContact, filter) {
    socialContact.trigger('filter', { filter: filter });
  },

  filterAll: function (filter) {
    this.collection.each(function (element) {
      this.filterOne(element, filter);
    }, this);
  },

  clearList: function () {
    this.$list.empty();
  },

  setListStyle: function (e) {
    var newListStyle = $(e.currentTarget).data('list-style');
    this.$list.removeClass(this.listStyle).addClass(newListStyle);
    this.listStyle = newListStyle;
  },

  setFilter: function (e) {
    this.filterAll(e.currentTarget.value);
  },
});

$('.sv-usercontactsall-portlet').each(function () {
  var $this = $(this),
    portletId = objectUtil.getObjectId($this.attr('id')),
    $list = $this.find('ol'),
    jsNamespace = $list.data('js-namespace'),
    userId = $list.data('user-identity'),
    template = getTemplate($this, 'contact'),
    socialContacts = new SocialContacts(sv[jsNamespace] || [], {
      id: userId,
      portletId: portletId,
    });

  new SocialContactsView({
    el: $this,
    collection: socialContacts,
    listStyle: 'sv-list-2-columns',
    socialContactTemplate: template,
  });

  $this.find('li.sv-social-contact').css('height', $list.data('item-height'));

  // Show 'label placeholder' for browsers that doesn't support HTML5 placeholders
  if (!clientUtil.supportsPlaceholder) {
    $this.find('.sv-filter-label').removeClass('env-d--none');
  }
});
