/**
 * Copyright (C) SiteVision AB 2002-2020, all rights reserved
 *
 * @author Ola Vognild
 */
import {
  getModelObjectUri,
  getPortletResourceUri,
} from '../../util/portletUtil';
import $ from '@sv/jquery';
import sv from '@sv/core';
import {
  i18n as _i18n,
  Events as events,
  ErrorUtil as errorUtil,
} from '@sv/util';
import _ from '@sv/underscore';

const i18cache = {};
const possibleMaliciousFileExtensions = ['htm', 'html', 'svg'];

export const i18nTimeline = (selector, args) => {
  var translated;

  if (!args && i18cache[selector]) {
    return i18cache[selector];
  }

  translated = _i18n.getText(
    'portlet.social.timeline.timeline',
    selector,
    args
  );

  if (!args) {
    i18cache[selector] = translated;
  }

  return translated;
};

export const fixMentionLinks = ($el, profilePageURL) => {
  $el.find('a[data-user-identity]').each(function () {
    var $this = $(this);
    $this.attr(
      'href',
      profilePageURL + '?identity=' + $this.data('user-identity')
    );
  });
};

export const fixTagLinks = ($el, tagResultPageURL) => {
  $el.find('a[data-social-tag-id]').each(function () {
    var $this = $(this);
    $this.attr(
      'href',
      tagResultPageURL +
        '?tag=' +
        encodeURIComponent($this.data('social-tag-name'))
    );
  });
};

export const inputConfig = {
  triggers: {
    '@': {
      source: getModelObjectUri(
        sv.PageContext.pageId,
        'userIdentitySearch',
        sv.PageContext.pageId
      ),
      searchKey: 'term',
      allowWhiteSpace: true,
      maxWhiteSpace: 2,
      parse: function (response) {
        return response.hits;
      },
    },
  },
  minLength: 2,
  replacer: function (item) {
    return (
      item.trigger + '{"value":"' + item.name + '","id":"' + item.id + '"}'
    );
  },
};

export const handleWarn = (message, heading) => {
  return this.publishMessage('', message, heading, true);
};

export const publishMessage = (level, heading, message, _transient) => {
  events.trigger(events.types.notifyUser, {
    type: level,
    heading: heading,
    message: message,
    transient: _transient,
  });
  return false;
};

export const toggleCommentsTemplate = _.template(
  '<i class="halflings-icon chevron-<%= direction %>"></i><%= text %>'
);

// Check if a file that is about to get uploaded will be accepted by the server
export const verifyFileUpload = (aName, aSize, aFolderId, aPortletId) => {
  var verified = false;

  $.ajax({
    type: 'GET',
    url: getPortletResourceUri(aPortletId, 'verifyUpload'),
    data: {
      repository: aFolderId,
      name: aName,
      size: aSize,
    },
    async: false,
  })
    .done(function (aResponse) {
      verified = aResponse.verified;
    })
    .fail(function (aResponse) {
      errorUtil.handleAjaxFailure(aResponse);
    });

  return verified;
};

export const isMaliciousFileType = (fileName) => {
  if (!fileName) {
    return false;
  }

  let extension = fileName.split('.').pop();

  extension = extension.toLowerCase();
  return possibleMaliciousFileExtensions.includes(extension);
};

export const truncateFileName = (fileName) => {
  const start = fileName.slice(0, 20) + '...';
  const end = fileName.substr(fileName.length - 10);

  return start + end;
};
