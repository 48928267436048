/*jshint nonew:false */
/**
* Copyright (C) SiteVision 2002-2020, all rights reserved
*
* @see notifications.vm
* @see notifications.css
* @see NotificationsAction
*
* @author micke
*/
import _ from '@sv/underscore';
import $ from '@sv/jquery';
import Backbone from '@sv/backbone';
import {getPortletResourceUri, getTemplate} from '../../util/portletUtil';
import loadingImg from '../../util/static/img/loading_16_grey.gif';
import {
  Ajax as ajax,
  Events as events,
  KeyUtil as keyUtil,
  i18n as _i18n,
  ObjectUtil as objectUtil,
} from '@sv/util';

const KEY =
  keyUtil.KEY,
  i18nPortlet = function(key, args) {
    return _i18n.getText('portlet.social.notifications.notifications', key, args);
  },
  i18nCommon = function(key, args) {
    return _i18n.getText('common', key, args);
  },
  handleError = function(message) {
    events.trigger(events.types.notifyUser, {
      type: 'error',
      heading: i18nCommon('error'),
      message: message
    });
    return false;
  };

let lastId = null;

// MODELS ------------------------------------------------
var Notification = Backbone.Model.extend({
  defaults: {
    id:'',
    isNew:false,
    message:'',
    targetUrl:'',
    notifier: {
      fullName: '',
      buddyIconUrl: ''
    }
  }
});

var Notifications = Backbone.Collection.extend({
  model: Notification,

  initialize: function(options) {
    this.id = options.id;
  },

  url: function() {
    return getPortletResourceUri(this.id, 'notifications');
  },

  parse: function(data) {
    this.hasMore = data.hasMore;
    return data.notifications;
  }
});

// VIEWS ------------------------------------------------
var NotificationView = Backbone.View.extend({
  tagName: 'li',

  className: 'sv-notification sv-clearfix',

  render: function() {
    this.$el.html(this.options.template(this.model.toJSON()));
    if (this.model.get('isNew')) {
      this.$el.find('.sv-notification-text').addClass('sv-notification-new');
    }
    return this;
  }
});

var NotificationsView = Backbone.View.extend({
  initialize: function() {
    this.$list = this.$el.find('ul.sv-notification-list');
    this.notificationTemplate = getTemplate(this.$el.closest('.sv-notifications-portlet'), 'notification');
    this.collection.on('reset', this.handleDataLoaded, this);
  },

  events: {
    'click .sv-notifications-show-more': 'loadMoreNotifications'
  },

  handleDataLoaded: function() {
    this.clearList();
    this.render();
  },

  loadMoreNotifications: function() {
    var that = this;

    if (lastId === false) {
      return;
    }

    var popoverContent = this.$list.closest('div.popover-content');
    popoverContent.css('max-height', popoverContent.height() + 'px').addClass('sv-show-scroll');

    if (lastId === null) {
      lastId = that.collection.last().get('id');
    }

    ajax.doGet({
      url: getPortletResourceUri(that.model.id, 'notifications') + '&last=' + lastId,

      success: function(data) {
        var showMore = that.$el.find('[data-sv-fn-show-more]'),
          notifications = data.notifications,
          hasMore = data.hasMore;

        $.each(notifications, function() {
          that.appendOne(new Notification(this));
        });
        popoverContent.scrollTop(popoverContent.scrollTop() + popoverContent.height());
        if ($(notifications).last().length > 0) {
          lastId = $(notifications).last().get(0).id;
        } else {
          lastId = false;
        }

        if (hasMore) {
          showMore.removeClass('env-d--none');
        } else {
          showMore.addClass('env-d--none');
        }
      },
      error: function() {
        handleError(i18nPortlet('error-unknown'));
      }
    });
  },

  render: function() {
    this.collection.each(function(item) {
      this.appendOne(item);
    }, this);
  },

  appendOne: function(notification) {
    var view = new NotificationView({
      model: notification,
      template: this.notificationTemplate
    });
    this.$list.append(view.render().el);
  },

  appendAll: function(notifications) {
    notifications.each(this.appendOne, this);
  },

  clearList: function() {
    this.$list.empty();
  }
});

// PORTLET INITIALIZATION --------------------------------------------------
var $body = $(document.body),
  inEditMode = $body.hasClass('sv-editing-mode');

$('.sv-notifications-portlet').each(function() {
  var $portlet = $(this),
    $toggleButton = $portlet.find('[data-fn-drop-down]'),
    loadingTimer, notificationsTemplate;

  notificationsTemplate = _.template('<ul class="sv-notification-list sv-defaultlist"></ul>' +
    '<div style="text-align:center; display:none" data-sv-fn-loading>' +
    '<img src="' + loadingImg + '" alt="" />' +
    '</div>' +
    '<div class="sv-notifications-show-more env-d--none" data-sv-fn-show-more><%= showMore %></div>');

  function loadNotifications() {
    var notifications = new Notifications({
      id: objectUtil.getObjectId($portlet.attr('id'))
    });

    new NotificationsView({
      el: $portlet,
      model: notifications,
      collection: notifications
    });

    notifications.fetch({
      reset: true,
      success: function(data) {
        var showMore = $portlet.find('[data-sv-fn-show-more]'),
          hasMore = data.hasMore,
          loading = $portlet.find('[data-sv-fn-loading]');

        clearTimeout(loadingTimer);
        loading.hide();

        if (hasMore) {
          showMore.removeClass('env-d--none');
        } else {
          showMore.addClass('env-d--none');
        }
      },

      error: function() {
        handleError(i18nPortlet('error-unknown'));
      }
    });
  }

  function showNotificationPopover() {
    if (inEditMode) {
      return;
    }

    $toggleButton
      .popover('destroy')
      .addClass('sv-fn-popover-showing')
      .popover({
        html: true,
        title: i18nPortlet('notifications'),
        placement: 'bottom',
        content: notificationsTemplate({ showMore: i18nPortlet('showMore') }),
        trigger: 'manual'
      });

    $toggleButton
      .popover('show')
      .on('keydown', handleKeydown)
      .on('keyup', handleKeyup);

    loadingTimer = setTimeout(function() {
      $portlet.find('[data-sv-fn-loading]').show();
    }, 400);

    loadNotifications();

    $body
      .off('click', handleClickOutside)
      .on('click', handleClickOutside);

  }

  function closeNotificationPopover() {
    $toggleButton
      .popover('destroy')
      .removeClass('sv-fn-popover-showing')
      .off('keydown', handleKeydown)
      .off('keyup', handleKeyup);

    $toggleButton.find('.sv-newnotification-badge').hide();
    $body.off('click', handleClickOutside);
  }

  function handleClickOutside(e) {
    var $target = $(e.target);

    if (!$target.is('.popover *') && !$target.is($toggleButton) && !$target.is($toggleButton.find('*'))) {
      closeNotificationPopover();
    }
  }

  function handleKeydown(e) {
    var key = keyUtil.getKeyCodeFromEvent(e);

    if (key === KEY.UP) {
      return false;
    } else if (key === KEY.DOWN) {
      return false;
    }
    return true;
  }

  function handleKeyup(e) {
    var key = keyUtil.getKeyCodeFromEvent(e);

    if (key === KEY.ESC) {
      closeNotificationPopover();
      return false;
    }
    return true;
  }

  function togglePopover(e) {
    e.preventDefault();
    if ($toggleButton.hasClass('sv-fn-popover-showing')) {
      closeNotificationPopover();
    } else {
      showNotificationPopover();
    }
  }

  $toggleButton
    .on('click', togglePopover)
    .bsPopoverAriaButton();
});
