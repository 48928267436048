/**
 * Copyright (C) SiteVision AB 2002-2019, all rights reserved
 *
 * moment.js language configuration
 * language : norwegian (no)
 */
var lang = {
    months : "januar_februar_mars_april_mai_juni_juli_august_september_oktober_november_desember".split("_"),
    monthsShort : "jan_feb_mar_apr_mai_jun_jul_aug_sep_okt_nov_des".split("_"),
    weekdays : "søndag_mandag_tirsdag_onsdag_torsdag_fredag_lørdag".split("_"),
    weekdaysShort : "søn_man_tis_ons_tor_fre_lør".split("_"),
    weekdaysMin : "sø_ma_ti_on_to_fr_lø".split("_"),
    longDateFormat : {
        LT : "HH:mm",
        L : "YYYY-MM-DD",
        LL : "D MMMM YYYY",
        LLL : "D MMMM YYYY LT",
        LLLL : "dddd D MMMM YYYY LT"
    },
    calendar : {
        sameDay: '[I dag klokken] LT',
        nextDay: '[I morgen klokken] LT',
        lastDay: '[I går klokken] LT',
        nextWeek: 'dddd [klokken] LT',
        lastWeek: '[Forrige] dddd [klokken] LT',
        sameElse: 'L'
    },
    relativeTime : {
        future : 'akkurat nå',
        past : '%s',
        s : 'akkurat nå',
        m : '1 min',
        mm : '%d min',
        h : '1 tim',
        hh : '%d tim',
        d : "i går",
        dd : "%d dager",
        M : "1 mån",
        MM : "%d mån",
        y : '1 år',
        yy : '%d år'
    },
    ordinal : '%d.'
};

export default lang;
