/**
 * Copyright (C) SiteVision AB 2002-2020, all rights reserved
 *
 * Social group status portlet functions.
 * @author Karl Eklöf
 */
import $ from '@sv/jquery';
import { getPortletResourceUri, getTemplate } from '../../util/portletUtil';
import Backbone from '@sv/backbone';
import { showSearchPopover } from '../util/SearchUserUtil';
import {
  Ajax as ajax,
  ObjectUtil as objectUtil,
  DialogUtil as dialogUtil,
  i18n as _i18n,
} from '@sv/util';

let forceReload = false,
  i18n = function (selector) {
    return _i18n.getText(
      'portlet.social.grouptypeselector.grouptypeselector',
      selector
    );
  },
  i18nCommon = function (selector) {
    return _i18n.getText('common', selector);
  };

var SocialIdentity = Backbone.Model.extend({
  url: function () {
    var memberId = this.get('id');
    if (this.collection) {
      return this.collection.url() + '&member=' + memberId;
    } else {
      return '?member=' + memberId;
    }
  },
});

var GroupAdminCollection = Backbone.Collection.extend({
  model: SocialIdentity,

  initialize: function (models, options) {
    this.portletId = options.portletId;
  },

  url: function () {
    return getPortletResourceUri(this.portletId, 'groupAdmins');
  },
});

var GroupItemView = Backbone.View.extend({
  tagName: 'li',

  className: 'sv-groupadmin-item',

  initialize: function () {
    this.template = this.options.template;
  },

  // Render HTML for group
  render: function () {
    this.$el.html(this.template(this.model.toJSON(), this.model));
    return this;
  },
});

var GroupAdminCollectionView = Backbone.View.extend({
  tagName: 'ol',

  events: {
    'click .sv-fn-groupadmin-add-admin': 'addNewAdmin',
  },

  initialize: function () {
    this.adminTemplate = this.options.adminTemplate;
    this.list = this.options.list;
    this.groupId = this.options.groupId;

    // Register a listener that will trigger when a group is added to the model
    this.collection.on('add', this.prependOne, this);
  },

  render: function () {
    this.clearList();

    this.collection.each(function (item) {
      this.appendOne(item);
    }, this);
  },

  addNewAdmin: function (e) {
    var that = this;
    showSearchPopover({
      target: e.currentTarget,
      placeholder: i18n('searchUser'),
      group: that.groupId,
      markAdmins: true,
      selected: function (value) {
        var admin = new SocialIdentity(value);
        that.collection.create(admin, {
          wait: true,
          success: function () {
            // added new Admin to list
            forceReload = true;

            /*events.trigger(events.types.notifyUser, {
                     'type'      : 'success',
                     'message'   : i18n('successAddAdminMessage', value.name),
                     'transient' : true,
                     'timer'     : 5
                  });*/
          },
        });
      },
    });
    return false;
  },

  appendOne: function (item) {
    var adminView = new GroupItemView({
      model: item,
      template: this.adminTemplate,
    });

    this.list.append(adminView.render().el);
  },

  prependOne: function (item) {
    var adminView = new GroupItemView({
      model: item,
      template: this.adminTemplate,
    });
    this.list.prepend(adminView.render().el);
  },

  clearList: function () {
    this.list.empty();
  },
});

var executeCommand = function (elem, command, reload) {
  var portletDivId = elem.closest('.sv-grouptypeselector-portlet').attr('id');
  var portletId = objectUtil.getObjectId(portletDivId);
  var commandURL =
    getPortletResourceUri(portletId, 'updateType') + '&groupCommand=' + command;

  ajax.doPut({
    url: commandURL,
    success: function () {
      if (reload) {
        location.reload();
      }
    },
    error: function () {
      $('.alert').show();
    },
  });
  return false;
};

var unpublishCommand = function (elem) {
  var portletDivId = elem.closest('.sv-grouptypeselector-portlet').attr('id');
  var portletId = objectUtil.getObjectId(portletDivId);
  var commandURL = getPortletResourceUri(portletId, 'unpublishGroup');

  dialogUtil.showConfirmDialog(
    i18n('unpublishGroupTitle'),
    i18n('unpublishGroupBody'),
    function (success) {
      if (success) {
        ajax.doDelete({
          url: commandURL,
          success: function (data) {
            window.location = data.parentURL;
          },
          error: function () {
            $('.alert').show();
          },
        });
      }
    }
  );

  return false;
};

var $groupTypeSelectorPortlet = $('.sv-grouptypeselector-portlet');

$groupTypeSelectorPortlet.on(
  'click',
  '.sv-fn-grouptypeselector-closed',
  function () {
    return executeCommand($(this), 'closed', true);
  }
);

$groupTypeSelectorPortlet.on(
  'click',
  '.sv-fn-grouptypeselector-open',
  function () {
    return executeCommand($(this), 'open', true);
  }
);

$groupTypeSelectorPortlet.on(
  'click',
  '.sv-fn-grouptypeselector-inactive',
  function () {
    return executeCommand($(this), 'inactive', true);
  }
);

$groupTypeSelectorPortlet.on(
  'click',
  '.sv-fn-grouptypeselector-active',
  function () {
    return executeCommand($(this), 'active', true);
  }
);

$groupTypeSelectorPortlet.on(
  'click',
  '.sv-fn-grouptypeselector-unpublish',
  function () {
    return unpublishCommand($(this));
  }
);

$groupTypeSelectorPortlet.on(
  'click',
  '.sv-fn-grouptypeselector-member_moderated',
  function () {
    return executeCommand($(this), 'member_moderated', true);
  }
);

$groupTypeSelectorPortlet.on(
  'click',
  '.sv-fn-grouptypeselector-settings',
  function () {
    // popup settings including name and description
    var $portlet = $(this).closest('.sv-grouptypeselector-portlet'),
      jsAdminNamespace = $portlet
        .find('[data-fn-grouptypeselector]')
        .data('js-namespace'),
      admins = window.sv[jsAdminNamespace];

    ajax.doGet({
      url: getPortletResourceUri(
        objectUtil.getObjectId($portlet.attr('id')),
        'getSettings'
      ),

      success: function (data) {
        showSettingsDialog(data, $portlet, admins);
      },

      error: function () {
        $('.alert').show();
      },
    });
  }
);

function showSettingsDialog(jsonResult, $portlet, admins) {
  var modalContent = $(
      $.parseHTML(getTemplate($portlet, 'group-settings')(jsonResult.values))
    ),
    adminList = modalContent.find('ol.sv-group-admins'),
    portletId = objectUtil.getObjectId($portlet.attr('id'));

  var adminCollection = new GroupAdminCollection(admins, {
    portletId: portletId,
  });

  var adminCollectionView = new GroupAdminCollectionView({
    el: modalContent,
    collection: adminCollection,
    adminTemplate: getTemplate($portlet, 'group-admin'),
    portletId: portletId,
    groupId: adminList.data('group'),
    list: adminList,
  });

  adminCollectionView.render();

  if (jsonResult.success) {
    var buttons = [
      {
        text: i18nCommon('ok'),
        skipDismiss: true,
        primary: true,
        callback: function () {
          ajax.doPost({
            data: $('.sv-groupsettings-form').serialize(),
            contentType: 'application/x-www-form-urlencoded',

            url: getPortletResourceUri(
              objectUtil.getObjectId($portlet.attr('id')),
              'updateSettings'
            ),

            success: function (data) {
              //post successful
              $('.modal').modal('hide');
              // reload page
              if (data.reload || forceReload) {
                if (data.updatedURL) {
                  window.location =
                    window.location.href.substring(
                      0,
                      window.location.href.indexOf('/', 8)
                    ) + data.updatedURL;
                } else {
                  location.reload();
                }
              }
            },
            error: function (data) {
              var result = JSON.parse(data.responseText);

              //var title = $('.alert').find('.alert-title');
              var message = $('.alert').find('.alert-message');
              message.text(i18n(result.messageType));

              modalContent
                .find('input')
                .first()
                .trigger('focus')
                .closest('.control-group')
                .addClass('error');

              $('.alert').show();
            },
          });
        },
      },
    ];
    dialogUtil.showDialog({
      title: i18n('group-settingsLabel'),
      body: modalContent,
      buttons: buttons,
      removeOnHide: true,
      errorTitle: i18n('errorTitle'),
      errorMsg: i18n('errorText'),
    });

    $('body').on('submit', 'form.sv-groupsettings-form', function () {
      return false;
    });
  }
}
