/*jshint evil:true*/ // eval has to be used when parsing 'data-alternatives' from data attributes since it contains single quotes
/**
 * Copyright (C) SiteVision AB 2002-2020, all rights reserved
 *
 * @author Karl Eklöf
 */
import _ from '@sv/underscore';
import $ from '@sv/jquery';
import {getPortletResourceUri, getModelObjectUri, getTemplate} from '../../util/portletUtil';
import {clearHashValue} from '../../util/windowLocationUtil';
import {
   Ajax as ajax,
   Events as events,
   ObjectUtil as objectUtil,
   ErrorUtil as errorUtil,
   DialogUtil as dialogUtil,
   i18n as _i18n,
} from '@sv/util';

 const i18cache = {},
   editTextTemplate = _.template(
      '<div>'+
      '  <textarea data-text-content><%= content %></textarea>'+
      '</div>');

// HELPERS ----------------------------------------------------
const i18n = function(selector, args) {
   var translated;

   if (!args && i18cache[selector]) {
      return i18cache[selector];
   }

   translated = _i18n.getText('portlet.social.fileview.file', selector, args);

   if (!args) {
      i18cache[selector] = translated;
   }
   return translated;
};
const i18nCommon = function(key, args) {
   return _i18n.getText('common', key, args);
};

var handleError = function(message,heading) {
   events.trigger(events.types.notifyUser, {
      type: 'error',
      heading: heading?heading:i18nCommon('error'),
      message: message
   });
   return false;
};

var split = function(val) {
   return val.split( /,\s*/ );
};

var getStoredValue = function() {
   if ('localStorage' in window && window.localStorage !== null) {
      return window.localStorage.getItem('sv-tags');
   }
};

var setStoredValue = function(val) {
   if ('localStorage' in window && window.localStorage !== null) {
      window.localStorage.setItem('sv-tags', JSON.stringify(val));
   }
};

// Check if a file that is about to get uploaded will be accepted by the server
var verifyFileUpload = function(aName, aSize, aFileId, aPortletId) {
   var verified = false;

   ajax.doGet({
      url : getPortletResourceUri(aPortletId, 'verifyUploadVersion'),
      async: false,
      data: {
         file: aFileId,
         name: aName,
         size: aSize
      },
      success: function(aResponse) {
         verified = aResponse.verified;
      },
      error: function(aResponse) {
         errorUtil.handleAjaxFailure(aResponse);
      }
   });

   return verified;
};

var lockfile = function($portlet, fileId, callback) {

   ajax.doPost({
      data: { file: fileId },
      url : getPortletResourceUri(objectUtil.getObjectId($portlet.attr('id')), 'lockFile'),

      success: function(aResponse) {
         if(aResponse.wasLocked) {
            events.trigger(events.types.notifyUser, {
               'type'      : 'success',
               'message'   : i18n('lockMessage', aResponse.lockInfo.displayName),
               'timer'     : 5
            });

            $portlet.find('.sv-lockinfo-container').html(getTemplate($portlet, 'lockinfo')(aResponse.lockInfo));
            $portlet.find('[data-fn-fileshare-unlock]').removeClass('env-d--none');
            $portlet.find('[data-fn-fileshare-lock]').addClass('env-d--none');
         } else {
            events.trigger(events.types.notifyUser, {
               'type'      : 'error',
               'message'   : i18n('lockErrorMessage', aResponse.lockInfo.displayName),
               'timer'     : 5
            });
         }

         callback && callback.call(this, aResponse);
      },

      error: function(aResponse) {
         errorUtil.handleAjaxFailure(aResponse);
      }
   });
};

var unlockfile = function($portlet, fileId) {
   ajax.doPost({
      data: { file: fileId },
      url : getPortletResourceUri(objectUtil.getObjectId($portlet.attr('id')), 'unlockFile'),

      success: function(aResponse) {
         if(aResponse.wasUnlocked) {
            // update lockinfo
            events.trigger(events.types.notifyUser, {
               'type'      : 'success',
               'message'   : i18n('unLockMessage'),
               'timer'     : 5
            });

            $portlet.find('.sv-lockinfo-container').empty();
            $portlet.find('[data-fn-fileshare-unlock]').addClass('env-d--none');
            $portlet.find('[data-fn-fileshare-lock]').removeClass('env-d--none');
         } else {
            events.trigger(events.types.notifyUser, {
               'type'      : 'error',
               'message'   : i18n('unlockErrorMessage', aResponse.lockInfo.displayName),
               'timer'     : 5
            });
         }

         reloadPortlet($portlet);
      },

      error: function(aResponse) {
         errorUtil.handleAjaxFailure(aResponse);
      }
   });
};

var toggleVersioned = function($portlet, fileId) {
   ajax.doPost({
      type: 'POST',
      dataType: 'JSON',
      data: { file: fileId },
      url : getPortletResourceUri(objectUtil.getObjectId($portlet.attr('id')), 'toggleVersioned'),

      success: function(data) {
         $portlet.find('.sv-lockinfo-container').empty();
         if (data.versioned) {
            $portlet.find('[data-fn-fileshare-notversioned]').addClass('env-d--none');
            $portlet.find('[data-fn-fileshare-versioned]').removeClass('env-d--none');
         }
         else {
            $portlet.find('[data-fn-fileshare-versioned]').addClass('env-d--none');
            $portlet.find('[data-fn-fileshare-notversioned]').removeClass('env-d--none');
         }

         reloadPortlet($portlet);
      },

      error: function(aResponse) {
         errorUtil.handleAjaxFailure(aResponse);
      }
   });
};

var deleteFile = function($portlet, fileId) {
   ajax.doDelete({
      url : getPortletResourceUri(objectUtil.getObjectId($portlet.attr('id')), 'delete') + '&file=' + fileId,
      success: function(aResponse) {
         window.location = aResponse.browseParentURL;
      },
      error: function(aResponse) {
         errorUtil.handleAjaxFailure(aResponse);
      }
   });
};

var reloadPortlet = function() {
   location.reload();
};

// Remove a specific version of a file
var deleteFileVersion = function($portlet, elem, fileId, uuid) {
   ajax.doDelete({
      url : getPortletResourceUri(objectUtil.getObjectId($portlet.attr('id')), 'deleteVersion') + '&file=' + fileId + '&version=' + uuid,
      success: function() {
         var table = elem.closest('table');
         elem.remove();

         var versionCount = table.find('tr[data-uuid]').length;
         var draftCount = table.find('tr[data-draft-uuid]').length;
         $('[data-fn-message-version-count]').text(versionCount+draftCount);

         if (versionCount === 1) {
            table.find('[data-fn-fileshare-delete-version-divider],[data-fn-fileshare-delete-version]').hide();
         }

         reloadPortlet($portlet);
      },
      error: function(aResponse) {
         errorUtil.handleAjaxFailure(aResponse);
      }
   });
};

var renameFile = function($portlet, fileId, aNewName) {
   ajax.doPost({
      type: 'POST',
      dataType: 'JSON',
      data: { file: fileId, name: aNewName},
      url : getPortletResourceUri(objectUtil.getObjectId($portlet.attr('id')), 'rename'),
      success: function() {
         reloadPortlet($portlet);
      },
      error: function(aResponse) {
         errorUtil.handleAjaxFailure(aResponse);
      }
   });
};

// Sets the currently 'active' version of a file
var toggleSelectedVersion = function($portlet, fileId, uuid) {
   ajax.doPost({
      data: { file: fileId, version: uuid },
      url : getPortletResourceUri(objectUtil.getObjectId($portlet.attr('id')), 'selectVersion'),
      success: function() {
         reloadPortlet($portlet);
      },
      error: function(aResponse) {
         errorUtil.handleAjaxFailure(aResponse);
      }
   });
};

var editTextFile = function($portlet, fileId) {
   var thePortletId = objectUtil.getObjectId($portlet.attr('id')),
      resourceUri = getPortletResourceUri(thePortletId, 'editTextFile');

   ajax.doGet({
      data: { file: fileId },
      url : resourceUri
   }).done(function(data) {
      var modalContent = $(editTextTemplate(data));
      var buttons = [
         {
            text: i18nCommon('cancel'),
            callback: function() {
               console.log('cancel...');
            }
         },
         {
            text: i18nCommon('save'),
            primary: true,
            callback: function() {
               // get data from fields in template
               var content = $('textarea[data-text-content]').val();

               // save content
               ajax.doPost({
                  url: resourceUri,
                  data: { file: fileId, content: content }
               });
            }
         }
      ];

      lockfile($portlet, fileId, function() {
         dialogUtil.showDialog({
            title: data.title,
            body: modalContent,
            buttons: buttons,
            hiddenCallback: function() {
               clearHashValue();
               unlockfile($portlet, fileId);
            }
         });
      });
   });
};

$('.sv-fileshareall-portlet').each(function () {
   import(/* webpackChunkName: "fileview-plugins" */ './plugins').then(() => {
      var $portlet = $(this),
         $fileEntry = $portlet.find('[data-fn-file-entry]');

      if ($fileEntry.length === 0) {
         return;
      }

      var locked = $fileEntry.data('locked'),
         editable = $fileEntry.data('editable'),
         comparable = $fileEntry.data('comparable'),
         selfLocked = $fileEntry.data('self-locked'),
         lockOverride = $fileEntry.data('lock-override'),
         modelObjectUrl = getModelObjectUri($fileEntry.data('file-id'), 'fileUpdate'),
         fileUploadElem = $portlet.find('.sv-fn-update'),
         progressElem =  $portlet.find('.sv-fn-upload-progress'),
         progressTimer = null,
         displayName = $fileEntry.data('file-name'),
         isVideo = $fileEntry.data('file-is-video'),
         tags = getStoredValue() ? split(getStoredValue()) : [];

      // activate editables
      $.fn.editable.defaults.mode = 'inline';

      if (window.location.hash === '#edit') {
         editTextFile($portlet, $fileEntry.data('file-id'));
      }

      $('[data-office-edit]').on('click', function() {
         var thePortletId = objectUtil.getObjectId($portlet.attr('id'));
         ajax.doGet({
            data: { file: $fileEntry.data('file-id') },
            url : getPortletResourceUri(thePortletId, 'accessToken'),
            success: function(aResponse) {
               window.location.href = aResponse.url;
            }
         });
         return false;
      });

      $('[data-text-edit]').on('click', function() {
         editTextFile($portlet, $fileEntry.data('file-id'));
         return false;
      });


      $('[data-lock-download]').on('click', function() {
         lockfile($portlet, $fileEntry.data('file-id'));
      });

      if ((editable && (!locked || selfLocked)) || lockOverride) {
         if (!locked || selfLocked)
         {
            $('.sv-metadata-editable-text, .sv-metadata-editable-date').editable({
               emptytext: i18n('emptyText'),
               url: getPortletResourceUri(objectUtil.getObjectId($portlet.attr('id')), 'setMetadata'),
               params: function(params) {
                     params.file = $fileEntry.data('file-id');
                     return params;
               }
            });

            $('.sv-version-description-editable').each(function() {
               $(this).editable({
                  emptytext:  i18n('emptyDescription'),
                  placement: 'right',
                  url: getPortletResourceUri(objectUtil.getObjectId($portlet.attr('id')), 'setVersionDescription'),
                  params: function(params) {
                        params.file = $fileEntry.data('file-id');
                        params.version = $(this).data('pk');
                        return params;
                  }
               });
            });

            $('.sv-metadata-editable-select').each(function() {
               $(this).editable({
                  emptytext:  i18n('emptyText'),
                  url: getPortletResourceUri(objectUtil.getObjectId($portlet.attr('id')), 'setMetadata'),
                  source: eval($(this).data('alternatives')),
                  params: function(params) {
                        params.file = $fileEntry.data('file-id');
                        return params;
                  }
               });
            });

            $('.sv-metadata-editable-tags').editable({
               inputclass: 'input-large',
               emptytext:  i18n('emptyText'),
               url: getPortletResourceUri(objectUtil.getObjectId($portlet.attr('id')), 'setTags'),
               select2: {
                  tags: tags,
                  formatNoMatches: function () { return ''; },
                  tokenSeparators: [',', ' '],
                  openOnEnter: false
               },
               params: function(params) {
                     params.file = $fileEntry.data('file-id') ;
                     return params;
               }
            }).on('save', function(e, params) {
               var result = getStoredValue() ? split(getStoredValue()).concat(params.newValue) : params.newValue;

               // save all tags in a cookie
               var saveItems = [];
               $.each(result, function(index, item) {
                  if ($.inArray(item, saveItems) === -1 && item !== '') {
                     saveItems.push(item);
                  }
               });

               setStoredValue(saveItems.join(','));
            }).on('shown', function() {
               window.setTimeout(function () {
                  $('.select2-search-field input').trigger('focus');
               }, 200);
            });


            $portlet.find('[data-fn-fileshare-rename]').on('click', function() {
               dialogUtil.showInputDialog(i18n('renameFileTitle'), i18n('renameFileMessage'), false, _.escape(displayName), function(data) {
                  // rename file
                  if (data.result) {
                     renameFile($portlet, $fileEntry.data('file-id'), data.text);
                  }
               });
            });
         }

         $portlet.find('[data-fn-fileshare-unlock]').on('click', function() {
            unlockfile($portlet, $fileEntry.data('file-id'));
         });

         $portlet.find('[data-fn-fileshare-lock]').on('click', function() {
            lockfile($portlet, $fileEntry.data('file-id'));
         });

         if (!locked || selfLocked)
         {
            $portlet.find('[data-fn-fileshare-versioned],[data-fn-fileshare-notversioned]').on('click', function() {
               if ($fileEntry.data('versioned')) {
                  dialogUtil.showConfirmDialog(i18n('versioning'), i18n('disableVersioning'),
                     function(result) {
                        if (result) {
                           toggleVersioned($portlet, $fileEntry.data('file-id'));
                        }
                     });
               } else {
                  toggleVersioned($portlet, $fileEntry.data('file-id'));
               }
            });

            $portlet.find('[data-fn-fileshare-delete-version]').on('click', function() {
               var elem = $(this).closest('tr'),
                  uuid = elem.data('uuid');

               dialogUtil.showConfirmDialog(i18n('remove'), i18n('removeVersion'),
                     function(result) {
                        if (result) {
                           deleteFileVersion($portlet, elem, $fileEntry.data('file-id'), uuid);
                        }
                     }
               );
               return false;
            });

            $portlet.find('[data-fn-fileshare-delete]').on('click', function() {
               dialogUtil.showConfirmDialog(i18n('remove'), i18n('removeFile'),
                     function(result) {
                        if (result) {
                           deleteFile($portlet, $fileEntry.data('file-id'));
                        }
                     }
               );
            });

            $portlet.find('.sv-file-compare').on('click', function() {
               var that = this;

               if (comparable) {
                  if ($('.sv-file-compare:checked').length === 2) {
                     $('.sv-file-compare').not(':checked').each(function() {
                        $(this).prop('disabled', true);
                     });
                  } else {
                     $('.sv-file-compare').each(function() {
                        $(this).prop('disabled', false);
                     });
                  }
               } else {
                  $('.sv-file-compare').each(function() {
                     if (this !== that) {
                        $(this).prop('checked', false);
                     }
                  });
               }

               var selectedVersions = $('.sv-file-compare:checked');
               if (selectedVersions.length === 1) {
                  var previewURL = $fileEntry.data('compare-base-url') + '/' + $(selectedVersions.get(0)).data('uuid') +
                     '/' + encodeURIComponent(displayName) + '.pdf';
                  $('.sv-compare-wrapper').html('<iframe src="' +previewURL +'"></iframe>');
               }
               else if (selectedVersions.length > 1 && comparable) {
                  var compareURL = $fileEntry.data('compare-base-url') + '/' +$(selectedVersions.get(0)).data('uuid') +
                     '/'+ $(selectedVersions.get(1)).data('uuid') + '/' + encodeURIComponent(displayName) + '.pdf';
                  $('.sv-compare-wrapper').html('<iframe src="' +compareURL +'"></iframe>');
               }
               else {
                  $('.sv-compare-wrapper').html('');
               }
            });

            $portlet.find('[data-fn-fileshare-select]').on('click', function() {
               toggleSelectedVersion($portlet, $fileEntry.data('file-id'), $(this).closest('tr').data('uuid'));

               var deselected = $(this).closest('tr').hasClass('success');

               // Clean up
               $portlet.find('tr.success').removeClass('success');
               $portlet.find('[data-fn-fileshare-select] i').removeClass('halflings-icon ok').addClass('sv-empty-icon');
               $portlet.find('i.sv-selection-marker').addClass('sv-empty-icon').removeClass('halflings-icon ok');

               if (!deselected) {
                  $(this).find('i').removeClass('sv-empty-icon').addClass('halflings-icon ok');
                  $(this).closest('tr').find('td i.sv-selection-marker').removeClass('sv-empty-icon').addClass('halflings-icon ok');
                  $(this).closest('tr').addClass('success');

               }

               $(this).closest('tr').find('.dropdown-toggle').dropdown('toggle');
               return false;
            });
         }
      }

      fileUploadElem.fileupload({
         dataType: 'json',
         url: modelObjectUrl,  // will do a POST of multi-parts (see FileUploadHandler.uploadParts)
         replaceFileInput: true,
         formAcceptCharset: 'utf-8',
         dropZone: $portlet,

         // before posting the data
         submit: function(e, data) {
            var file = data.files[0],
               fileSize = file.size;

            // IE8/9 workaround (no size support)
            if (!fileSize) {
               fileSize = 1;
            }

            // Make sure that the file that is about to get uploaded is accepted
            if (!verifyFileUpload(file.name, fileSize, $fileEntry.data('file-id'), objectUtil.getObjectId($portlet.attr('id')))) {
               return false;
            }

            progressTimer = setTimeout(function() {
               progressElem.removeClass('env-d--none');
            }, 500);
         },

         done: function () { // result populated by ProfileImageHandler.uploadParts
            reloadPortlet($portlet);
         },

         fail: function() {
            //TODO: Do something intelligent with errorcodes supplied in responsedata
            return handleError(i18n('error-unknown'));
         },

         // after post (failed or successful)
         always: function() {
            if (progressTimer !== null) {
               window.clearTimeout(progressTimer);
            }

            progressElem.addClass('env-d--none');
         }
      });
   });
});

