/**
 * Copyright (C) SiteVision AB 2012, all rights reserved
 *
 * Social group status portlet functions.
 * @author Magnus Lövgren
 */

import sv from '@sv/core';
import $ from '@sv/jquery';
import { doGetWithFade } from '../../util/portletUtil';
import { ObjectUtil as objectUtil } from '@sv/util';

const executeCommand = function (elem, command, callback) {
  let identityId = elem.attr('data-identity-id'),
    portletDivId = elem.closest('.sv-groupmemberstatus-portlet').attr('id'),
    portletId = objectUtil.getObjectId(portletDivId);
  doGetWithFade(
    '#' + portletDivId,
    '/' +
      sv.PageContext.pageId +
      '/' +
      portletId +
      '.htm?identity=' +
      identityId +
      '&groupStatusCommand=' +
      command,
    callback
  );

  return false;
};

const $portlet = $('.sv-groupmemberstatus-portlet');

$portlet.on('click', '.sv-fn-groupmemberstatus-add', function () {
  return executeCommand($(this), 'add', function () {
    window.location.reload();
  });
});

$portlet.on('click', '.sv-fn-groupmemberstatus-eavesdrop', function () {
  return executeCommand($(this), 'eavesdrop', function () {
    window.location.reload();
  });
});

$portlet.on('click', '.sv-fn-groupmemberstatus-uneavesdrop', function () {
  return executeCommand($(this), 'uneavesdrop', function () {
    window.location.reload();
  });
});

$portlet.on('click', '.sv-fn-groupmemberstatus-remove', function () {
  var profilePageURL = $(this).attr('data-profile-page');

  return executeCommand($(this), 'remove', function () {
    if (profilePageURL === '') {
      window.location.reload();
    } else {
      window.location.href = profilePageURL;
    }
  });
});

$portlet.on('click', '.sv-fn-groupmemberstatus-mute', function () {
  return executeCommand($(this), 'mute', function () {});
});

$portlet.on('click', '.sv-fn-groupmemberstatus-unmute', function () {
  return executeCommand($(this), 'unmute', function () {});
});

$portlet.on(
  'click',
  '.sv-fn-groupmemberstatus-addSkipNotifications',
  function () {
    return executeCommand($(this), 'addSkipNotifications', function () {});
  }
);

$portlet.on(
  'click',
  '.sv-fn-groupmemberstatus-removeSkipNotifications',
  function () {
    return executeCommand($(this), 'removeSkipNotifications', function () {});
  }
);

$portlet.on('click', '.sv-fn-groupmemberstatus-eavesdrop', function () {
  return executeCommand($(this), 'eavesdrop', function () {});
});

$portlet.on('click', '.sv-fn-groupmemberstatus-uneavesdrop', function () {
  return executeCommand($(this), 'uneavesdrop', function () {});
});
