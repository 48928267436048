/**
 * Copyright (C) SiteVision AB 2002-2020, all rights reserved
 *
 * Social contact status portlet functions.
 * @author Magnus Lövgren
 */

import sv from '@sv/core';
import $ from '@sv/jquery';
import { doGetWithFade } from '../../util/portletUtil';
import { ObjectUtil as objectUtil } from '@sv/util';

const executeCommand = function (elem, command) {
  var contactId = elem.attr('data-contact-id'),
    portletDivId = elem.closest('.sv-contactstatus-portlet').attr('id'),
    portletId = objectUtil.getObjectId(portletDivId);
  doGetWithFade(
    '#' + portletDivId,
    '/' +
      sv.PageContext.pageId +
      '/' +
      portletId +
      '.htm?identity=' +
      contactId +
      '&contactStatusCommand=' +
      command,
    function () {
      if (
        'add' === command ||
        'remove' === command ||
        command === 'disable' ||
        command === 'enable'
      ) {
        location.reload();
      }
    }
  );
  return false;
};

let $contactStatusPortlet = $('.sv-contactstatus-portlet');

$contactStatusPortlet.on('click', '.sv-fn-contactstatus-add', function () {
  return executeCommand($(this), 'add');
});
$contactStatusPortlet.on('click', '.sv-fn-contactstatus-remove', function () {
  return executeCommand($(this), 'remove');
});
$contactStatusPortlet.on('click', '.sv-fn-contactstatus-mute', function () {
  return executeCommand($(this), 'mute');
});
$contactStatusPortlet.on('click', '.sv-fn-contactstatus-unmute', function () {
  return executeCommand($(this), 'unmute');
});
$contactStatusPortlet.on('click', '.sv-fn-contactstatus-disable', function () {
  return executeCommand($(this), 'disable');
});
$contactStatusPortlet.on('click', '.sv-fn-contactstatus-enable', function () {
  return executeCommand($(this), 'enable');
});
$contactStatusPortlet.on('click', '.sv-fn-contactstatus-hide', function () {
  return executeCommand($(this), 'hide');
});
$contactStatusPortlet.on('click', '.sv-fn-contactstatus-show', function () {
  return executeCommand($(this), 'show');
});
