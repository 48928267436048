/* global envision:false, svDocReady: false */
/**
 * Copyright (C) SiteVision AB 2002-2020, all rights reserved
 *
 * @author albin
 */
import sv from '@sv/core';
import _ from '@sv/underscore';
import $ from '@sv/jquery';
import { getPortletResourceUri } from '../../util/portletUtil';
import {
  Ajax as ajax,
  DialogUtil as dialogUtil,
  ErrorUtil as errorUtil,
  Events as events,
  i18n as _i18n,
  ObjectUtil as objectUtil,
} from '@sv/util';
import {
  fixMentionLinks,
  fixTagLinks,
  i18nTimeline,
  inputConfig,
} from './utils';
import CommentsView from './view/Comments';
import CommentsCollection from './collection/Comments';

let userIdentity, maxCharacterCount;

const i18nCommon = function (key, args) {
  return _i18n.getText('common', key, args);
};

//Portlet init
$('.sv-timelineentry-portlet').each(function () {
  import(/* webpackChunkName: "timeline-plugins" */ './plugins').then(() => {
    const $portlet = $(this),
      $timelineEntry = $portlet.find('[data-timeline-entry]'),
      portletId = objectUtil.getObjectId($portlet.attr('id')),
      jsNamespace = $timelineEntry.data('js-namespace'),
      portletSettings = sv[jsNamespace];

    if (!portletSettings) {
      return;
    }

    let isGroupPage = portletSettings.isGroupPage,
      isActiveGroup = portletSettings.isActiveGroup,
      isGroupAdmin = portletSettings.isGroupAdmin,
      isEntryEditable = portletSettings.isEntryEditable,
      authorId = portletSettings.authorId,
      entryId = portletSettings.entryId,
      entryOwnerId = portletSettings.entryOwnerId,
      allowEmojis = portletSettings.allowEmojis;

    userIdentity = portletSettings.userIdentity; // "GLOBAL" shared between timeline entry portlets on page
    maxCharacterCount = portletSettings.maxCharacterCount;

    function checkBookmarkEntry() {
      ajax
        .doGet({
          url: getPortletResourceUri(portletId, 'bookmarkTimelineEntry'),
          data: {
            entry: entryId,
          },
        })
        .done(function (response) {
          var personal = $portlet.find(
            '[data-fn-bookmark-entry][data-type="personal"]'
          );
          if (response.bookmarked) {
            personal
              .addClass('remove-bookmark')
              .text(i18nTimeline('removeBookmarkTitle'));
          } else {
            personal
              .removeClass('remove-bookmark')
              .text(i18nTimeline('addBookmarkTitle'));
          }

          var group = $portlet.find(
            '[data-fn-bookmark-entry][data-type="group"]'
          );
          if (response.bookmarkedInGroup) {
            group
              .addClass('remove-bookmark')
              .text(i18nTimeline('removeGroupBookmarkTitle'));
          } else {
            group
              .removeClass('remove-bookmark')
              .text(i18nTimeline('addGroupBookmarkTitle'));
          }
        });
    }

    function toggleBookmarkEntry(e) {
      var elem = $(e.currentTarget),
        type = $(e.currentTarget).data('type'),
        remove = elem.hasClass('remove-bookmark');

      if (remove) {
        removeBookmarkEntry(e, type);
      } else {
        addBookmarkEntry(e, type);
      }

      return false;
    }

    function addBookmarkEntry(e, type) {
      ajax
        .doPost({
          url: getPortletResourceUri(portletId, 'bookmarkTimelineEntry'),
          data: {
            entry: entryId,
            type: type,
          },
        })
        .done(function (response) {
          if (response.addedBookmark) {
            events.trigger(events.types.notifyUser, {
              type: 'success',
              heading: i18nTimeline('successBookmarkTitle'),
              transient: true,
              timer: 3,
            });
          } else if (response.message) {
            events.trigger(events.types.notifyUser, {
              type: 'error',
              heading: response.message,
              transient: true,
              timer: 3,
            });
          }
        })
        .fail(function (response) {
          errorUtil.handleAjaxFailure(response);
        });

      $(e.currentTarget)
        .closest('.dropdown')
        .find('.dropdown-toggle')
        .dropdown('toggle');

      return false;
    }

    function removeBookmarkEntry(e, type) {
      ajax
        .doDelete({
          url:
            getPortletResourceUri(portletId, 'bookmarkTimelineEntry') +
            '&entry=' +
            entryId +
            '&type=' +
            type,
        })
        .done(function (response) {
          if (response.removedBookmark) {
            events.trigger(events.types.notifyUser, {
              type: 'success',
              heading: i18nTimeline('successRemovedBookmarkTitle'),
              transient: true,
              timer: 3,
            });
          } else if (response.message) {
            events.trigger(events.types.notifyUser, {
              type: 'error',
              heading: response.message,
              transient: true,
              timer: 3,
            });
          }
        })
        .fail(function (response) {
          errorUtil.handleAjaxFailure(response);
        });

      $(e.currentTarget)
        .closest('.dropdown')
        .find('.dropdown-toggle')
        .dropdown('toggle');

      return false;
    }

    function showDestroyDialog() {
      var heading, body;

      if (
        !requireActiveGroup() ||
        (authorId !== userIdentity && !isGroupAdmin)
      ) {
        return false;
      }

      heading = i18nTimeline('removeEntryTitle');
      body = i18nTimeline('removeEntryBody');

      dialogUtil.showConfirmDialog(heading, body, function (success) {
        if (success) {
          ajax
            .doDelete({
              url:
                getPortletResourceUri(portletId, 'timelineEntry') +
                '&entry=' +
                entryId,
              data: {
                entry: entryId,
              },
            })
            .done(function () {
              $timelineEntry.html('');
            })
            .fail(function (response) {
              errorUtil.handleAjaxFailure(response);
            });
        }
      });

      return false;
    }

    function startEditEntry(e) {
      e.preventDefault();

      if (!requireActiveGroup() || !isEntryEditable) {
        return false;
      }

      ajax
        .doGet({
          url:
            getPortletResourceUri(portletId, 'editEntry') +
            '&timelineOwner=' +
            entryOwnerId +
            '&entry=' +
            entryId,
        })
        .done(function (response) {
          showEditEntry(response.plainMessage, response.mentions);
        })
        .fail(function (response) {
          errorUtil.handleAjaxFailure(response);
        });
    }

    function showEditEntry(message, mentions) {
      var $charactersLeft = $(
          '<span class="sv-float-right">' +
            (maxCharacterCount - message.length) +
            '</span>'
        ),
        $messageField = $('<textarea/>')
          .attr('id', 'editEntry')
          .addClass('sv-timeline-input sv-border-box')
          .on('keyup', (e) => {
            var text = e.currentTarget.value;
            var charactersLeft = maxCharacterCount - text.length;
            var $saveEditButton = getSaveEditEntryButton();

            if (charactersLeft < 0 || text.trim() === '') {
              $charactersLeft.addClass('sv-character-limit-exceeded');
              $saveEditButton.addClass('disabled').prop('disabled', true);
            } else {
              $charactersLeft.removeClass('sv-character-limit-exceeded');
              $saveEditButton.removeClass('disabled').prop('disabled', false);
            }

            $charactersLeft.text(charactersLeft);
          })
          .val(message),
        modalContent = $('<div/>')
          .addClass('sv-timeline-portlet')
          .append($messageField)
          .append($charactersLeft),
        buttons = [
          {
            text: i18nCommon('save'),
            callback: function () {
              ajax
                .doPut({
                  url:
                    getPortletResourceUri(portletId, 'timelineEntry') +
                    '&timelineOwner=' +
                    entryOwnerId +
                    '&entry=' +
                    entryId,
                  data: {
                    message: $messageField.triggeredInput('getRichContent'),
                    entry: entryId,
                  },
                })
                .done(function (updatedEntry) {
                  var $commentableInfo = $timelineEntry.find(
                    '#sv-commentable-info'
                  );

                  $timelineEntry
                    .find(
                      '[data-commentable-id=' +
                        objectUtil.getHtmlId(updatedEntry.id) +
                        ']'
                    )
                    .html(updatedEntry.message);

                  if ($commentableInfo.find('li.sv-edited').length === 0) {
                    $commentableInfo.append(
                      '<li class="sv-edited"><i class="halflings-icon edit"></i>' +
                        _.escape(updatedEntry.modifiedBy) +
                        '</li>'
                    );
                  }
                })
                .fail(function (response) {
                  errorUtil.handleAjaxFailure(response);
                });
            },
          },
        ];

      dialogUtil.showDialog({
        title: i18nTimeline('editEntryTitle'),
        body: modalContent,
        buttons: buttons,
        shownCallback: function () {
          $messageField
            .triggeredInput(inputConfig)
            .triggeredInput('setIds', mentions)
            .elastic();
        },
      });

      return false;
    }

    function getSaveEditEntryButton() {
      return $('.modal-footer button');
    }

    function requireActiveGroup() {
      if ((isGroupPage && isActiveGroup) || !isGroupPage) {
        return true;
      }

      events.trigger(events.types.notifyUser, {
        type: 'error',
        heading: i18nCommon('error'),
        message: i18nTimeline('inactiveGroupMessage'),
      });

      return false;
    }

    svDocReady(() =>
      envision.imageViewer2(
        $portlet[0].querySelectorAll('[data-fn-gallery-container]')
      )
    );

    // bookmark actions
    $('[data-toggle="dropdown"]').on('mousedown', checkBookmarkEntry);
    $('[data-fn-bookmark-entry]').on('click', toggleBookmarkEntry);
    $('[data-fn-edit-entry]').on('click', startEditEntry);
    $('[data-fn-destroy-entry]').on('click', showDestroyDialog);

    const getEmojiPicker = (position) => {
      // EmojiButton will be exposed globally when loaded (dynamically)
      return new window.EmojiButton({
        position: position,
        i18n: {
          search: i18nTimeline('search'),
          categories: {
            recents: i18nTimeline('recents'),
            smileys: i18nTimeline('smileys'),
            animals: i18nTimeline('animals'),
            food: i18nTimeline('food'),
            activities: i18nTimeline('activities'),
            travel: i18nTimeline('travel'),
            objects: i18nTimeline('objects'),
            symbols: i18nTimeline('symbols'),
          },
        },
      });
    };

    (this.comments = new CommentsCollection(portletSettings.comments || [], {
      id: entryId,
      portletId: portletId,
      entryOwnerId: portletSettings.entryOwnerId,
      userIdentity: portletSettings.userIdentity,
    })),
      (this.commentsView = new CommentsView({
        el: $portlet,
        collection: this.comments,
        allowEmojis: allowEmojis,
        portletId: portletId,
        maxCharacterCount: portletSettings.maxCharacterCount,
        profilePageURL: portletSettings.profilePageURL,
        tagResultPageURL: portletSettings.tagResultPageURL,
        userIdentity: userIdentity,
        getEmojiPicker,
        requireActiveGroup: requireActiveGroup,
      }));

    fixMentionLinks($portlet, portletSettings.profilePageURL);
    fixTagLinks($portlet, portletSettings.tagResultPageURL);

    if (portletSettings.useOembed) {
      var $this = $(this);

      events.trigger(events.types.updateOembedLinks, $this);
    }
  });
});
