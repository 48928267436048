// moment.js language configuration
// language : swedish (sv)
// author : Jens Alm : https://github.com/ulmus
var lang = {
    months : "januari_februari_mars_april_maj_juni_juli_augusti_september_oktober_november_december".split("_"),
    monthsShort : "jan_feb_mar_apr_maj_jun_jul_aug_sep_okt_nov_dec".split("_"),
    weekdays : "söndag_måndag_tisdag_onsdag_torsdag_fredag_lördag".split("_"),
    weekdaysShort : "sön_mån_tis_ons_tor_fre_lör".split("_"),
    weekdaysMin : "sö_må_ti_on_to_fr_lö".split("_"),
    longDateFormat : {
        LT : "HH:mm",
        L : "YYYY-MM-DD",
        LL : "D MMMM YYYY",
        LLL : "D MMMM YYYY LT",
        LLLL : "dddd D MMMM YYYY LT"
    },
    calendar : {
        sameDay: '[Idag klockan] LT',
        nextDay: '[Imorgon klockan] LT',
        lastDay: '[Igår klockan] LT',
        nextWeek: 'dddd [klockan] LT',
        lastWeek: '[Förra] dddd[en klockan] LT',
        sameElse: 'L'
    },
    relativeTime : {
        future : "alldeles nyss",
        past : "%s",
        s : "alldeles nyss",
        m : "1 min",
        mm : "%d min",
        h : "1 tim",
        hh : "%d tim",
        d : "i går",
        dd : "%d dagar",
        M : "1 mån",
        MM : "%d mån",
        y : "1 år",
        yy : "%d år"
    },
    ordinal : function (number) {
        var b = number % 10;
        return (~~ (number % 100 / 10) === 1) ? 'e' :
            (b === 1) ? 'a' :
            (b === 2) ? 'a' :
            (b === 3) ? 'e' : 'e';
    }
};

export default lang;
