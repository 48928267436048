/**
 * Copyright (C) SiteVision AB 2002-2020, all rights reserved
 *
 * @author Niclas Hedlund
 */
import sv from '@sv/core';

// Set the 'hash' portion of the browser URL
export const setHashValue = function (aNamespace, aValue) {
  window.location.hash = '#' + aNamespace + '/' + aValue;
};

// Set the 'hash' portion of the browser URL
export const getHashValue = function (aNamespace) {
  var hash = window.location.hash;
  var valueIndex = 2 + aNamespace.length;

  if (hash.indexOf('#' + aNamespace + '/') === 0 && hash.length > valueIndex) {
    return hash.substring(valueIndex);
  } else {
    return;
  }
};

// Clear the 'hash' portion of the browser URL
export const clearHashValue = function () {
  window.location.hash = '';
};

// Check if a valid 'hash' portion is available in the browser URL
export const hasHashValue = function () {
  var hash = window.location.hash;
  return hash.indexOf('#') === 0 && hash.indexOf('/') > 1;
};

// Reload the whole page
export const reloadPage = function () {
  location.reload();
};

sv.WindowLocationUtil = {
  setHashValue,
  getHashValue,
  clearHashValue,
  hasHashValue,
  reloadPage,
};
