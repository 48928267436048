/*
 * Copyright (C) SiteVision AB 2002-2020, all rights reserved
 *
 * @author calle
 *
 */
import sv from '@sv/core';
import $ from '@sv/jquery';
import { getTemplate } from '../../util/portletUtil';
import {
  Events as events,
  ObjectUtil as objectUtil,
  Log as log,
  i18n as _i18n,
} from '@sv/util';
import { i18nTimeline } from './utils';
import TimelineCollection from './collection/Timeline';
import TimelineView from './view/Timeline';

let isActiveGroup, isGroupPage, userIdentity, maxCharacterCount;

// HELPERS ----------------------------------------------------
var i18nCommon = function (key, args) {
  return _i18n.getText('common', key, args);
};

var requireActiveGroup = function () {
  if ((isGroupPage && isActiveGroup) || !isGroupPage) {
    return true;
  }

  events.trigger(events.types.notifyUser, {
    type: 'error',
    heading: i18nCommon('error'),
    message: i18nTimeline('inactiveGroupMessage'),
  });

  return false;
};

// PORTLET INITIALIZATION --------------------------------------------------
$(
  '.sv-timeline-portlet,.sv-tagtimeline-portlet,.sv-timelinesearch-portlet'
).each(function () {
  import(/* webpackChunkName: "timeline-plugins" */ './plugins').then(
    ({ moment }) => {
      var $portlet = $(this),
        portletId = objectUtil.getObjectId($portlet.attr('id')),
        $list = $portlet.find('ol'),
        jsNamespace = $list.data('js-namespace'),
        portletSettings = sv[jsNamespace],
        timelineOwner,
        timelineChannel;

      if (!portletSettings) {
        // Happens when the user is not authorized...
        return;
      }

      timelineOwner = portletSettings.timelineOwner;
      timelineChannel = portletSettings.timelineChannel;

      // the same for all portlets on the page
      isGroupPage = portletSettings.isGroupPage;
      isActiveGroup = portletSettings.isActiveGroup;
      userIdentity = portletSettings.userIdentity;
      maxCharacterCount = portletSettings.maxCharacterCount;

      const getEmojiPicker = (position) => {
        // EmojiButton will be exposed globally when loaded (dynamically)
        return new window.EmojiButton({
          position: position,
          i18n: {
            search: i18nTimeline('search'),
            categories: {
              recents: i18nTimeline('recents'),
              smileys: i18nTimeline('smileys'),
              animals: i18nTimeline('animals'),
              food: i18nTimeline('food'),
              activities: i18nTimeline('activities'),
              travel: i18nTimeline('travel'),
              objects: i18nTimeline('objects'),
              symbols: i18nTimeline('symbols'),
            },
          },
        });
      };

      var timeline = new TimelineCollection(portletSettings.entries || [], {
          id: timelineOwner,
          timelineChannel,
          portletId,
          addSharedEntries: timelineOwner === userIdentity,
          isCompoundTimeline: portletSettings.isCompoundTimeline,
          isPersonalTimeline: portletSettings.isPersonalTimeline,
          isTagTimeline: portletSettings.isTagTimeline,
          userIdentity,
          isGroupAdmin: portletSettings.isGroupAdmin,
          isUserIdentityDisabled: portletSettings.isUserIdentityDisabled,
          moment,
        }),
        timelineView = new TimelineView({
          el: $portlet,
          collection: timeline,
          timelineEntryTemplate: getTemplate($portlet, 'timeline-entry'),
          portletId,
          timelineOwner,
          tagResultPageURL: portletSettings.tagResultPageURL,
          profilePageURL: portletSettings.profilePageURL,
          loadMoreCount: portletSettings.loadMoreCount,
          isTimelineSearch: portletSettings.isTimelineSearch,
          useOembed: portletSettings.useOembed,
          allowEmojis: portletSettings.allowEmojis,
          maxCharacterCount,
          requireActiveGroup,
          userIdentity,
          getEmojiPicker,
        });

      log.debug(
        null,
        'initialized portlet ' +
          portletId +
          " and it's timelineView " +
          timelineView.cid
      );
    }
  );
});
