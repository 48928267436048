/**
 * Copyright (C) SiteVision AB 2002-2020, all rights reserved
 *
 * @author sjostrand
 *
 * Handles personal settings and userfields for current user.
 */
import $ from '@sv/jquery';
import { getPortletUri, getTemplate } from '../../util/portletUtil';
import { popupUserSettingsDialog } from '../util/userSettingsUtil';
import {
  ObjectUtil as objectUtil,
  DialogUtil as dialogUtil,
  Ajax as ajax,
  i18n as _i18n,
} from '@sv/util';

const i18n = function (selector) {
    return _i18n.getText(
      'portlet.social.personalsettings.personalsettings',
      selector
    );
  },
  i18nCommon = function (selector) {
    return _i18n.getText('common', selector);
  };

$('[data-fn-personalsettings-profile]').on('click', function () {
  var that = this;
  var fields = $(that).attr('data-fields');

  popupUserSettingsDialog(fields);
});

$('[data-fn-personalsettings-general]').on('click', function () {
  var $portlet = $(this).closest('.sv-personalsettings-portlet');
  ajax.doGet({
    url: getPortletUri(
      objectUtil.getObjectId($portlet.attr('id')),
      'generalSettings',
      true
    ),
    success: function (data) {
      showSettingsDialog(
        data,
        $portlet,
        'storeGeneralSettings',
        'personalsettings-general'
      );
    },
    error: function () {
      $('.alert').show();
    },
  });
});

$('[data-fn-personalsettings-notification]').on('click', function () {
  var $this = $(this);
  var $portlet = $this.closest('.sv-personalsettings-portlet');
  var isMobileAppEnabled = $this.attr('data-app-enabled');

  ajax
    .doGet({
      context: this,
      url: getPortletUri(
        objectUtil.getObjectId($portlet.attr('id')),
        'notificationSettings',
        true
      ),
    })
    .done(function (data) {
      if (isMobileAppEnabled === 'true') {
        showSettingsDialog(
          data,
          $portlet,
          'storePushNotificationSettings',
          'personalsettings-push-notifications'
        );
      } else {
        showSettingsDialog(
          data,
          $portlet,
          'storeNotificationSettings',
          'personalsettings-notifications'
        );
      }
    })
    .fail(function () {
      $('.alert').show();
    });
});

function showSettingsDialog(jsonResult, $portlet, actionName, templateName) {
  var modalContent = $(
    $.parseHTML(getTemplate($portlet, templateName)(jsonResult.values))
  );
  if (jsonResult.success) {
    var buttons = [
      {
        text: i18nCommon('cancel'),
      },
      {
        text: i18nCommon('ok'),
        skipDismiss: true,
        primary: true,
        callback: function () {
          ajax.doPost({
            url: getPortletUri(
              objectUtil.getObjectId($portlet.attr('id')),
              actionName,
              true
            ),
            data: $('.sv-personalsettings-form').serialize(),
            contentType: 'application/x-www-form-urlencoded',
            success: function (data) {
              if (data.success) {
                //response was marked OK
                $('.modal').modal('hide');
              } else {
                if (data.errors) {
                  //Response supplied error messages
                  var errors = data.errors;
                  for (var key in errors) {
                    //Iterate over keys in error messages
                    if (errors.hasOwnProperty(key)) {
                      var failedField = $('[data-fn-' + key + ']'); //find appropriate inputfiled and set error.
                      if (failedField.siblings('.error').length === 0) {
                        failedField.after(
                          $('<span />')
                            .addClass('help-block error')
                            .text(errors[key])
                        );
                        failedField.parents('.control-group').addClass('error');
                      }
                    }
                  }
                }
              }
            },
            error: function () {
              //post failed, show generic error message
              $('.alert').show();
            },
          });
        },
      },
    ];
    var helpLabel = '';
    if (templateName === 'personalsettings-notifications') {
      helpLabel = i18n(templateName + 'Help');
    }
    dialogUtil.showDialog({
      title: i18n(templateName + 'Label'),
      titleHelp: helpLabel,
      body: modalContent,
      buttons: buttons,
      removeOnHide: true,
      errorTitle: i18n('networkErrorTitle'),
      errorMsg: i18n('networkErrorText'),
    });
  }
}
