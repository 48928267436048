/**
 * Copyright (C) SiteVision AB 2002-2020, all rights reserved
 *
 * Will send like/unlike requests to the calling portlet.
 * Every portlet that uses this utility must be able to
 * respond to these requests.
 *
 * @author albin
 */
import $ from '@sv/jquery';
import _ from '@sv/underscore';
import { Log as log,
  i18n as _i18n,
  Events as events,
  ErrorUtil as errorUtil,
  ObjectUtil as objectUtil,
} from '@sv/util';
import {getPortletResourceUri} from '../../util/portletUtil';

const likeTemplate = _.template(
    '<i class="halflings-icon thumbs-up"></i><%= likeText %> (<%= likeCount %>)'
  ),
  i18cache = {};

var i18n = function (selector) {
  var translated;

  if (i18cache[selector]) {
    return i18cache[selector];
  }

  translated = _i18n.getText('portlet.social.util.likableutil', selector);

  return translated;
};

var updateLikeText = function ($likable) {
  var likeCount = $likable.data('like-count') || 0,
    userLikes = !!$likable.data('user-likes');

  $likable.html(
    likeTemplate({
      likeText: userLikes ? i18n('likes') : i18n('like'),
      likeCount: likeCount,
      userLikes: userLikes,
    })
  );

  $likable[userLikes ? 'addClass' : 'removeClass']('sv-liked');
};

var updateLikeTexts = function ($scope) {
  $scope = $scope || $('body');

  if ($scope.is('[data-fn-likable],[data-fn-portlet-likable]')) {
    updateLikeText($scope);
    return;
  }

  $scope.find('[data-fn-likable],[data-fn-portlet-likable]').each(function () {
    updateLikeText($(this));
  });
};

var sendLikeRequest = function (likeURL, likableId, userLikes) {
  return $.ajax({
    url: likeURL,
    method: userLikes ? 'DELETE' : 'POST',
    dataType: 'JSON',
    data: { likable: likableId },
  });
};

var getUsersThatLike = function (likeURL) {
  return $.ajax({
    url: likeURL,
    method: 'GET',
    dataType: 'JSON',
  });
};

var showTooltip = function (elem, likes) {
  elem.tooltip({
    html: true,
    title: likes.join('<br/>'),
  });
  elem.tooltip('show');
};

export const toggleLike = function () {
  var $this = $(this),
    userLikes = !!$this.data('user-likes'),
    likableId = $this.data('likable-id'),
    portletDiv,
    portletId,
    url;

  $this.removeData('likes');

  if (!likableId) {
    log.warn(
      'Trying to like object without likable-id in portlet ' +
        $this.closest('.sv-portlet').attr('id')
    );
    return;
  }

  if ($this.hasClass('sv-disabled')) {
    return;
  }

  $this.addClass('sv-disabled');

  portletDiv = $this.closest('.sv-portlet');
  portletId = objectUtil.getObjectId(portletDiv.attr('id'));

  if (userLikes) {
    url = getPortletResourceUri(portletId, 'unlike') + '&likable=' + likableId;
  } else {
    url = getPortletResourceUri(portletId, 'like');
  }

  sendLikeRequest(url, likableId, userLikes)
    .done(function (response) {
      $this
        .data('user-likes', !userLikes) // toggle the state of userLikes
        .data('like-count', response.count);
      updateLikeText($this);
    })
    .fail(function (aResponse) {
      errorUtil.handleAjaxFailure(aResponse);
    })
    .always(function () {
      $this.removeClass('sv-disabled');
    });

  return false;
};

$('body').on('click.likeable', 'a[data-fn-likable]', toggleLike);

$(document).on('mouseenter', '.sv-like', function () {
  var $this = $(this),
    portletDiv = $this.closest('.sv-portlet'),
    portletId = objectUtil.getObjectId(portletDiv.attr('id')),
    likableId = $this.data('likable-id'),
    likes = $this.data('likes'),
    likeCount = $this.data('like-count');

  if (likeCount > 0) {
    if (likes) {
      showTooltip($this, likes);
    } else {
      var url =
      getPortletResourceUri(portletId, 'likes') +
        '&likable=' +
        likableId;
      getUsersThatLike(url)
        .done(function (response) {
          $this.data('likes', response);
          showTooltip($this, response);
        })
        .fail(function (aResponse) {
          errorUtil.handleAjaxFailure(aResponse);
        });
    }
  }
});

events.on(events.types.updateLikables, updateLikeTexts);

updateLikeTexts(); // Set initial values
