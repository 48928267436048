/**
 * Copyright (C) SiteVision AB 2002-2020, all rights reserved
 *
 */
import Backbone from '@sv/backbone';
import { i18nTimeline } from '../utils';

var TimelineEntry = Backbone.Model.extend({
  defaults: {
    message: '',
    likes: 0,
    commentsCount: 0,
    date: 0,
    isRemovable: false,
    entryOwnerId: null,
    author: {
      fullName: '',
      largeBuddyIconURL: '/sitevision/core/profile.png',
    },
    removeTitle: i18nTimeline('removeEntryTitle'),
    editTitle: i18nTimeline('editEntryTitle'),
    addBookmarkTitle: i18nTimeline('addBookmarkTitle'),
    addGroupBookmarkTitle: i18nTimeline('addGroupBookmarkTitle'),
  },

  initialize: function () {
    var author, entryOwnerId;
    if (this.collection) {
      this.listenTo(this.collection, 'markAsRead', this.markAsRead);
      this.on('markEntryAsRead', this.markAsRead);

      if (this.collection.isCompoundTimeline) {
        author = this.get('author');
        entryOwnerId = this.get('entryOwnerId');

        if (
          (author.id !== this.collection.userIdentity &&
            entryOwnerId !== this.collection.userIdentity) ||
          author.postedInGroup
        ) {
          this.set('removeTitle', i18nTimeline('hideEntryTitle'));
        }
      }
    }
  },

  markAsRead: function () {
    this.set('markAsUnread', false);
  },

  url: function () {
    if (this.collection) {
      return this.collection.url() + '&entry=' + this.get('id');
    }

    return '?entry=' + this.get('id');
  },
});

export default TimelineEntry;
