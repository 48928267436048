/**
 * Copyright (C) SiteVision AB 2002-2020, all rights reserved
 *
 * @see taglist.vm
 * @see taglist.css
 * @see TagListAction
 *
 * @author nh
 */

import $ from '@sv/jquery';
import {
  doGetWithFade,
  getPortletUri,
  getPortletResourceUri,
} from '../../util/portletUtil';
import {
  Ajax as ajax,
  Events as events,
  KeyUtil as keyUtil,
  i18n as _i18n,
  ObjectUtil as objectUtil,
} from '@sv/util';

const KEY = keyUtil.KEY,
  i18nPrefix = 'portlet.social.taglist.taglist';

// Add a new tag to user
var executeAdd = function (elem, inputTagText, addButton) {
  var tagText = inputTagText.val();

  if (tagText && tagText !== '#') {
    var portletDiv = elem.closest('.sv-taglist-portlet'),
      portletDivId = portletDiv.attr('id'),
      portletId = objectUtil.getObjectId(portletDivId);

    ajax.doPost({
      url: getPortletResourceUri(portletId, 'create'),
      data: { tag: tagText },
      success: function () {
        var list = portletDiv.find('.sv-taglist');
        doGetWithFade(list, getPortletUri(portletId, 'viewItems'));
        inputTagText.val('');
        addButton.addClass('disabled').attr('disabled', 'disabled');
      },
    });
  }
  return false;
};

// Remove tag from user
var executeRemove = function (elem) {
  var itemTag = elem.closest('.sv-taglist-item'),
    tagText = itemTag.attr('data-tag-name'),
    portletDiv = elem.closest('.sv-taglist-portlet'),
    portletDivId = portletDiv.attr('id'),
    portletId = objectUtil.getObjectId(portletDivId),
    removeURL =
      getPortletResourceUri(portletId, 'remove') +
      '&tag=' +
      window.encodeURIComponent(tagText);

  ajax.doDelete({
    url: removeURL,
    success: function () {
      events.trigger(events.types.notifyUser, {
        type: 'success',
        message: _i18n.getText(i18nPrefix, 'removedMessage', tagText),
        transient: true,
        timer: 5,
      });

      var list = portletDiv.find('.sv-taglist'),
        viewURL =
          getPortletUri(portletId, 'view') + '&onlyRenderListItems=true';

      doGetWithFade(list, viewURL);
    },
  });

  return false;
};

// Bind function to add button
var $tagListPortlet = $('.sv-taglist-portlet');

$tagListPortlet.on('click', '.sv-fn-taglist-add-portlet', function () {
  const $this = $(this);
  return executeAdd(
    $this,
    $this.closest('.sv-taglist-portlet').find('.sv-taglist-input'),
    $this.closest('.sv-taglist-portlet').find('[data-add-tag-button]')
  );
});

$tagListPortlet.on('keyup', '.sv-taglist-input', function (e) {
  var key = keyUtil.getKeyCodeFromEvent(e),
    addButton = $(this)
      .closest('.sv-taglist-portlet')
      .find('[data-add-tag-button]');

  if ($(e.currentTarget).val().length > 0) {
    // enable button
    addButton.removeClass('disabled').prop('disabled', false);
  } else {
    // disable button
    addButton.addClass('disabled').attr('disabled', 'disabled');
  }

  if (key === KEY.RETURN) {
    const $this = $(this);
    executeAdd(
      $this,
      $this.closest('.sv-taglist-portlet').find('.sv-taglist-input'),
      addButton
    );
  } else if (key === KEY.SPACE) {
    return false;
  }
});

// handle the clear field x in IE
$tagListPortlet.on('mouseup', '.sv-taglist-input', function (e) {
  var addButton = $(this)
      .closest('.sv-taglist-portlet')
      .find('[data-add-tag-button]'),
    $target = $(e.currentTarget),
    oldValue = $target.val();

  if (oldValue === 0) {
    addButton.addClass('disabled').attr('disabled', 'disabled');
  } else {
    // When this event is fired after clicking on the clear button
    // the value is not cleared yet. We have to wait for it.
    setTimeout(function () {
      var newValue = $target.val();
      if (newValue.length === 0) {
        addButton.addClass('disabled').attr('disabled', 'disabled');
      }
    }, 1);
  }
});

$tagListPortlet.on('click', 'li.sv-taglist-item', function () {
  location.href = $(this).find('.sv-fn-tag-link').attr('href');
});

$tagListPortlet.on('click', '.sv-fn-taglist-remove-portlet', function () {
  return executeRemove($(this));
});
