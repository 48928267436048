/*jshint nonew:false */
/**
 * Copyright (C) SiteVision AB 2002-2020, all rights reserved
 *
 * @see chat.vm
 * @see chat.css
 * @see NotificationsAction
 *
 * @author Karl Eklöf
 */
import _ from '@sv/underscore';
import $ from '@sv/jquery';
import Backbone from '@sv/backbone';
import { getPortletResourceUri, getTemplate } from '../../util/portletUtil';
import loadingImg from '../../util/static/img/loading_16_grey.gif';
import {
  Events as events,
  ObjectUtil as objectUtil,
  Ajax as ajax,
  KeyUtil as keyUtil,
  i18n as _i18n,
} from '@sv/util';

const KEY = keyUtil.KEY,
  i18nPortlet = function (key, args) {
    return _i18n.getText('portlet.social.messages.messages', key, args);
  },
  i18nCommon = function (key, args) {
    return _i18n.getText('common', key, args);
  },
  handleError = function (message) {
    events.trigger(events.types.notifyUser, {
      type: 'error',
      heading: i18nCommon('error'),
         message: message
    });
    return false;
  };

let showMoreCount = null;

// MODELS ------------------------------------------------
var Conversation = Backbone.Model.extend({
  defaults: {
    id: '',
    name: '',
    isNew: false,
    message: '',
    unreadCount: 0,
    notifier: {
      fullName: '',
         buddyIconUrl: ''
      }
   }
});

var Conversations = Backbone.Collection.extend({
  model: Conversation,

  initialize: function (options) {
    this.id = options.id;
  },

  url: function () {
    return getPortletResourceUri(this.id, 'conversation');
  },

  parse: function (data) {
    this.hasMore = data.hasMore;
    return data.conversations;
   }
});

// VIEWS ------------------------------------------------
var ConversationView = Backbone.View.extend({
  tagName: 'li',

  className: 'sv-conversation sv-clearfix',

  events: {
      'click': 'showMessagePanel'
  },

  showMessagePanel: function (e) {
    const conversationId = $(e.currentTarget)
      .find('a[data-conversation]')
      .data('conversation');
    events.trigger('showMessages', false, conversationId);

    $('.sv-fn-popover-showing')
      .popover('destroy')
      .removeClass('sv-fn-popover-showing');
    showMoreCount = null;
    return false;
  },

  render: function () {
    this.$el.html(this.options.template(this.model.toJSON()));
    if (this.model.get('isNew')) {
      this.$el.find('.sv-conversation-text').addClass('sv-conversation-new');
    }
    return this;
   }
});

var ConversationsView = Backbone.View.extend({
  initialize: function () {
    this.$list = this.$el.find('ul.sv-conversation-list');
    this.conversationTemplate = getTemplate(
      this.$el.closest('.sv-messages-portlet'),
      'conversation'
    );
    this.collection.on('reset', this.handleDataLoaded, this);
  },

  events: {
      'click .sv-conversations-show-more': 'loadMoreConversations'
  },

  handleDataLoaded: function () {
    this.clearList();
    this.render();
  },

  loadMoreConversations: function () {
    if (showMoreCount === false) {
      return;
    }

    var popoverContent = this.$list.closest('div.popover-content');
    popoverContent
      .css('max-height', popoverContent.height() + 'px')
      .addClass('sv-show-scroll');

    if (showMoreCount === null) {
      showMoreCount = 1;
    }

    ajax.doGet({
      url:
        getPortletResourceUri(this.model.id, 'conversation') +
        '&showMoreCount=' +
        showMoreCount,
      context: this,
      success: function (data) {
        var showMore = this.$el.find('[data-sv-fn-show-more]'),
          conversations = data.conversations,
          hasMore = data.hasMore;

        this.clearList();
        _.each(
          conversations,
          function (conversation) {
            this.appendOne(new Conversation(conversation));
          },
          this
        );
        popoverContent.scrollTop(
          popoverContent.scrollTop() + popoverContent.height()
        );

        if (hasMore) {
          showMoreCount += 1;
          showMore.removeClass('env-d--none');
        } else {
          showMoreCount = false;
          showMore.addClass('env-d--none');
        }
      },

      error: function () {
        handleError(i18nPortlet('error-unknown'));
         }
    });
  },

  render: function () {
    this.collection.each(function (item) {
      this.appendOne(item);
    }, this);
  },

  appendOne: function (conversation) {
    var view = new ConversationView({
      model: conversation,
         template: this.conversationTemplate
    });
    this.$list.append(view.render().el);
  },

  appendAll: function (conversations) {
    conversations.each(this.appendOne, this);
  },

  clearList: function () {
    this.$list.empty();
   }
});

var $body = $(document.body),
  inEditMode = $body.hasClass('sv-editing-mode');

$('.sv-messages-portlet').each(function () {
  const $portlet = $(this),
    $toggleButton = $portlet.find('[data-fn-drop-down]');

  let $currentToggler = $toggleButton,
    loadingTimer;

  const conversationsTemplate = _.template(
    '<ul class="sv-conversation-list sv-defaultlist"></ul>' +
      '<div style="text-align:center; display:none" data-sv-fn-loading>' +
      '<img src="' + loadingImg + '" alt="" />' +
      '</div>' +
      '<div class="sv-conversations-show-more env-d--none" data-sv-fn-show-more><%= showMore %></div>'
  );

  function loadConversations() {
    var conversations = new Conversations({
         id: objectUtil.getObjectId($portlet.attr('id'))
    });

    new ConversationsView({
      el: $portlet,
      model: conversations,
         collection: conversations
    });

    conversations.fetch({
      reset: true,
      success: function (data) {
        var showMore = $portlet.find('[data-sv-fn-show-more]'),
          hasMore = data.hasMore,
          loading = $portlet.find('[data-sv-fn-loading]');

        clearTimeout(loadingTimer);
        loading.hide();

        if (hasMore) {
          showMore.removeClass('env-d--none');
        } else {
          showMore.addClass('env-d--none');
        }
      },

      error: function () {
        handleError(i18nPortlet('error-unknown'));
         }
    });
  }

  function showConversationPopover($target) {
    if (inEditMode) {
      return;
    }

    $currentToggler.popover('destroy');
      $currentToggler = ($target && $target.length > 0) ? $target : $toggleButton;
    $currentToggler.addClass('sv-fn-popover-showing').popover({
      html: true,
      title:
        i18nPortlet('conversations') +
        '<a class="pull-right" href="#" data-new-conversation>' +
        i18nPortlet('newMessage') +
        '</a></div>',
      placement: 'bottom',
      content: conversationsTemplate({ showMore: i18nPortlet('showMore') }),
            trigger: 'manual'
    });

    $currentToggler
      .popover('show')
      .on('keydown', handleKeydown)
      .on('keyup', handleKeyup);

    loadingTimer = setTimeout(function () {
      $portlet.find('[data-sv-fn-loading]').show();
    }, 400);
    loadConversations();

    $body.off('click', handleClickOutside).on('click', handleClickOutside);

    $portlet.on('click', '[data-new-conversation]', handleNewConversation);
  }

  function closeConversationPopover() {
    showMoreCount = null;

    if ($currentToggler.hasClass('sv-fn-popover-showing')) {
      $currentToggler
        .popover('destroy')
        .removeClass('sv-fn-popover-showing')
        .off('keydown', handleKeydown)
        .off('keyup', handleKeyup)
        .find('[data-fn-count]')
        .removeClass('sv-conversations-badge')
        .text('');
    }

    $body.off('click', handleClickOutside);
    $portlet.off('click', '[data-new-conversation]', handleNewConversation);
  }

  function handleClickOutside(e) {
    var $target = $(e.target);
    if (
      !$target.is('.popover *') &&
      !$target.is($currentToggler) &&
      !$target.is($currentToggler.find('*'))
    ) {
      closeConversationPopover();
    }
  }

  function handleKeydown(e) {
    var key = keyUtil.getKeyCodeFromEvent(e);
    if (key === KEY.UP) {
      return false;
    } else if (key === KEY.DOWN) {
      return false;
    }
    return true;
  }

  function handleKeyup(e) {
    var key = keyUtil.getKeyCodeFromEvent(e);
    if (key === KEY.ESC) {
      closeConversationPopover();
      return false;
    }
    return true;
  }

  function handleNewConversation(e) {
    e.preventDefault();
    closeConversationPopover();
    events.trigger('showMessages', false);
  }

  function togglePopover(e) {
    e.preventDefault();

    var $target = $(e.currentTarget);

      if ($currentToggler && ($currentToggler[0] === $target[0]) && $currentToggler.hasClass('sv-fn-popover-showing')) {
      closeConversationPopover();
    } else {
      showConversationPopover($target);
    }
  }

  $toggleButton.on('click', togglePopover).bsPopoverAriaButton();

  events.on('showConversations', function (e) {
    togglePopover(e);
  });

  // open atmosphere connection here for signaling
  events.on('server-ping-' + $toggleButton.data('channel'), function (message) {
    events.trigger('showMessages', true, message.conversation);
  });
});
