/**
 * Copyright (C) SiteVision AB 2002-2020, all rights reserved
 *
 * @author eric
 */
import $ from '@sv/jquery';
import sv from '@sv/core';
import {
  doGetWithFade,
  getPortletUri,
  getPortletResourceUri,
} from '../../util/portletUtil';
import {
  Ajax as ajax,
  ObjectUtil as objectUtil,
  i18n as _i18n,
} from '@sv/util';

const i18n = function (key, args) {
    return _i18n.getText('portlet.social.sharedlinks.sharedlinks', key, args);
  },
  i18nCommon = function (key, args) {
    return _i18n.getText('common', key, args);
  };

$('.sv-sharedlinks-portlet [data-add-link]').on('click', function () {
  var buttons = [
    {
      text: i18n('share'),
      callback: function () {
        var url = $inputUrl.val(),
          title = $inputTitle.val(),
          $sharedlinksForm = $('.sv-sharedlinks-form'),
          $removeUrlHasError = $('input[name="url"]').removeClass(
            'sv-sharedlinks-has-error'
          ),
          $removeTitleHasError = $('input[name="title"]').removeClass(
            'sv-sharedlinks-has-error'
          );

        $sharedlinksForm.find('.sv-sharedlinks-error-message').remove(),
          $sharedlinksForm
            .closest('.modal-body')
            .removeClass('sv-sharedlinks-highlight-flash');

        if (url !== '' && title !== '') {
          $removeUrlHasError;
          if (urlValidation(url)) {
            ajax.doPost({
              url: getPortletResourceUri(portletId, 'create'),
              data: {
                url: url,
                title: title,
              },
              success: function () {
                var list = $sharedlinksPortletDiv.find('.sv-sharedlinks-list');
                doGetWithFade(list, getPortletUri(portletId, 'viewItems'));
              },
            });
          } else {
            $sharedlinksForm
              .closest('.modal-body')
              .addClass('sv-sharedlinks-highlight-flash');
            $removeTitleHasError;
            errorMessage(title, 'url', 'urlIsNotALink');

            return false;
          }
        } else {
          $sharedlinksForm
            .closest('.modal-body')
            .addClass('sv-sharedlinks-highlight-flash');

          if (url === '') {
            errorMessage(url, 'url', 'addressRequired');
          } else if (!urlValidation(url)) {
            errorMessage(url, 'url', 'urlIsNotALink');
          } else {
            $removeUrlHasError;
          }

          if (title !== '') {
            $removeTitleHasError;
          }

          errorMessage(title, 'title', 'titleRequired');

          return false;
        }
      },
    },
  ];

  var $sharedlinksPortletDiv = $(this).closest('.sv-sharedlinks-portlet'),
    portletDivId = $sharedlinksPortletDiv.attr('id'),
    portletId = objectUtil.getObjectId(portletDivId);

  var $inputUrl = $(
      '<input class="input-block-level" type="text" name="url" id="' +
        portletDivId +
        '-url" placeholder="' +
        i18n('placeholderUrl') +
        '" autofocus/> '
    ),
    $inputTitle = $(
      '<input class="input-block-level" type="text" name="title" id="' +
        portletDivId +
        '-title" />'
    );

  var $modalContent = $('<div/>'),
    $form = $('<form class="sv-sharedlinks-form" />');

  $form.append(
    $('<label for="' + portletDivId + '-title">' + i18n('title') + '</label>')
  );
  $form.append($inputTitle);
  $form.append(
    $('<label for="' + portletDivId + '-url">' + i18n('address') + '</label>')
  );
  $form.append($inputUrl);
  $form.append($('<button type="submit" class="env-d--none" tabindex="-1">'));

  $modalContent.append($form);

  sv.DialogUtil.showDialog({
    title: i18n('createdSharedLink'),
    body: $modalContent,
    buttons: buttons,
  });
});

$('.sv-sharedlinks-portlet').on('click', '[data-remove-link]', function () {
  var buttons = [
    {
      text: i18nCommon('ok'),
      primary: true,
      callback: $.proxy(function () {
        ajax.doDelete({
          url:
            getPortletResourceUri(portletId, 'delete') +
            '&id=' +
            $(this).data('link-id'),
          success: function () {
            var list = $sharedlinksPortletDiv.find('.sv-sharedlinks-list'),
              viewURL =
                getPortletUri(portletId, 'view') + '&onlyRenderListItems=true';

            doGetWithFade(list, viewURL);
          },
        });
      }, this),
    },
    {
      text: i18nCommon('cancel'),
      callback: false,
    },
  ];

  var $sharedlinksPortletDiv = $(this).closest('.sv-sharedlinks-portlet'),
    portletDivId = $sharedlinksPortletDiv.attr('id'),
    portletId = objectUtil.getObjectId(portletDivId);

  var $modalContent = $('<div/>');
  $modalContent.append($('<p>' + i18n('removeShareLink') + '</p>'));

  sv.DialogUtil.showDialog({
    title: i18n('removeLink'),
    body: $modalContent,
    buttons: buttons,
  });
});

$('.sv-sharedlinks-portlet').on('click', '[data-edit-link]', function () {
  var buttons = [
    {
      text: i18n('save'),
      callback: function () {
        var url = $inputUrl.val(),
          title = $inputTitle.val(),
          $sharedlinksForm = $('.sv-sharedlinks-form'),
          $removeUrlHasError = $('input[name="url"]').removeClass(
            'sv-sharedlinks-has-error'
          ),
          $removeTitleHasError = $('input[name="title"]').removeClass(
            'sv-sharedlinks-has-error'
          );

        $sharedlinksForm.find('.sv-sharedlinks-error-message').remove();
        $sharedlinksForm
          .closest('.modal-body')
          .removeClass('sv-sharedlinks-highlight-flash');
        if (url !== '' && title !== '') {
          $removeUrlHasError;
          if (urlValidation(url)) {
            ajax.doPut({
              url: getPortletResourceUri(portletId, 'update'),
              data: {
                id: linkId,
                url: url,
                title: title,
              },
              success: function () {
                var list = $sharedlinksPortletDiv.find('.sv-sharedlinks-list');
                doGetWithFade(list, getPortletUri(portletId, 'viewItems'));
              },
            });
          } else {
            $sharedlinksForm
              .closest('.modal-body')
              .addClass('sv-sharedlinks-highlight-flash');
            $removeTitleHasError;
            errorMessage(url, 'url', 'urlIsNotALink');

            return false;
          }
        } else {
          $sharedlinksForm
            .closest('.modal-body')
            .addClass('sv-sharedlinks-highlight-flash');

          if (url === '') {
            errorMessage(url, 'url', 'addressRequired');
          } else if (!urlValidation(url)) {
            errorMessage(url, 'url', 'urlIsNotALink');
          } else {
            $removeUrlHasError;
          }

          if (title !== '') {
            $removeTitleHasError;
          }

          errorMessage(title, 'title', 'titleRequired');

          return false;
        }
      },
    },
  ];

  var $sharedlinksPortletDiv = $(this).closest('.sv-sharedlinks-portlet'),
    portletDivId = $sharedlinksPortletDiv.attr('id'),
    portletId = objectUtil.getObjectId(portletDivId),
    linkId = $(this).data('edit-link-id'),
    linkUrl = $(this).data('link-url'),
    linkTitle = $(this).data('link-title');

  var $inputUrl = $(
      '<input class="input-block-level" type="text" name="url" id="' +
        portletDivId +
        '-url" value="' +
        linkUrl +
        '" placeholder="' +
        i18n('placeholderUrl') +
        '" />'
    ),
    $inputTitle = $(
      '<input class="input-block-level" type="text" name="title" id="' +
        portletDivId +
        '-title" value="' +
        linkTitle +
        '"/>'
    );

  var $modalContent = $('<div/>'),
    $form = $('<form class="sv-sharedlinks-form" />');

  $form.append(
    $('<label for="' + portletDivId + '-title">' + i18n('title') + '</label>')
  );
  $form.append($inputTitle);
  $form.append(
    $('<label for="' + portletDivId + '-url">' + i18n('address') + '</label>')
  );
  $form.append($inputUrl);
  $form.append($('<button type="submit" class="env-d--none" tabindex="-1">'));

  $modalContent.append($form);

  sv.DialogUtil.showDialog({
    title: i18n('editSharedLink'),
    body: $modalContent,
    buttons: buttons,
  });
});

var urlValidation = function (url) {
  return /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/g.test(
    url
  );
};

var errorMessage = function (input, inputName, errorMsg) {
  if (errorMsg === 'urlIsNotALink' || input === '') {
    var $errorMessage = $('input[name="' + inputName + '"]');
    $errorMessage.after(function () {
      $errorMessage.addClass('sv-sharedlinks-has-error');
      return (
        '<p class="sv-sharedlinks-error-message">' + i18n(errorMsg) + '</p>'
      );
    });
  }
};
