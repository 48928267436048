/**
 * Copyright (C) SiteVision AB 2002-2020, all rights reserved
 *
 * @author Karl Eklöf
 */
import sv from '@sv/core';
import $ from '@sv/jquery';
import _ from '@sv/underscore';
import Backbone from '@sv/backbone';
import { getModelObjectUri } from '../../../util/portletUtil';
import { Events as events, i18n as _i18n } from '@sv/util';

let i18cache = {};

function i18n(selector, args) {
  var translated;

  if (!args && i18cache[selector]) {
    return i18cache[selector];
  }

  translated = _i18n.getText(
    'portlet.social.util.userinformation',
    selector,
    args
  );

  if (!args) {
    i18cache[selector] = translated;
  }
  return translated;
}

var SocialContact = Backbone.Model.extend({
  defaults: {
    fields: [],
    contacts: 0,
    groups: 0,
    showSendMessage: false,
  },

  url: function () {
    return getModelObjectUri(sv.PageContext.pageId, 'userStatus', this.id);
  },
});

var SocialContactPopOverView = Backbone.View.extend({
  tagName: 'div',

  className: 'bootstrap',

  template: _.template(
    '<div class="bootstrap"><img style="float:left; margin-right:8px;" class="sv-buddy-icon" src="<%= largeBuddyIcon %>" />' +
      '<div style="overflow: hidden" class="sv-userfields">' +
      '    <% _.each(fields, function(field) { %>' +
      '       <%- field %><br/>' +
      '    <% }); %>' +
      '</div>' +
      '<div style="clear:left; padding-top:10px; margin-bottom:3px" class="sv-clearfix">' +
      '    <div style="float:left;margin-top: 7px;"><img src="/sitevision/core/<%= status %>.png"/></div>' +
      '       <div style="float:left;margin-top: 4px;"><small><%= statusText %></small></div>' +
      '       <div style="float:right;padding-left: 10px;">' +
      "         <% if (showAddContact && contact === 'false') { %>" +
      '           <button data-user-identity="<%= id %>" class="btn btn-mini btn-default sv-add-contact">' +
      i18n('addAsContact') +
      '</button>' +
      '         <% } %>' +
      "         <% if (showSendMessage && self === 'false') { %>" +
      '           <button data-user-identity="<%= id %>" class="btn btn-mini btn-primary sv-send-message"><i class="halflings-icon comments white"></i> ' +
      i18n('message') +
      '</button>' +
      '         <% } %>' +
      '       </div>' +
      '    </div>' +
      '</div>'
  ),

  getContent: function () {
    return this.template(this.model.toJSON());
  },
});

function loadPopover($elem) {
  var contactId = $elem.data('user-identity'),
    subTitle = $elem.data('fn-show-user-popup-subtitle'),
    title =
      _.escape($elem.attr('title')) +
      (subTitle ? '<small> ' + _.escape(subTitle) + '</small>' : ''),
    url = getModelObjectUri(sv.PageContext.pageId, 'userStatus', contactId);

  $elem.addClass('user-popover-loaded').webuiPopover({
    trigger: 'hover',
    type: 'async',
    title: title,
    placement: 'auto-top',
    url: url,
    content: function (data) {
      data = JSON.parse(data); // We have to do this our selves since webui-popover doesn't set the dataType on their ajax request
      $.extend(data, {
        showAddContact:
          data.userIdentityAvailable && data.requestedUserIdentityAvailable,
        showSendMessage:
          data.userIdentityAvailable &&
          data.requestedUserIdentityAvailable &&
          $('.sv-messages-portlet').length > 0,
      });

      var view = new SocialContactPopOverView({
        model: new SocialContact(data),
      });
      return view.getContent();
    },
  });
}

$('body').on(
  'mouseenter',
  '[data-user-identity][data-fn-show-user-popup]',
  function () {
    import(/* webpackChunkName: "popover-plugins" */ './plugins').then(() => {
      var $this = $(this);
      if (!$this.hasClass('user-popover-loaded')) {
        loadPopover($this);
        $this.trigger('mouseenter');
      }
    });
  }
);

$('body').on(
  'click',
  'button.sv-send-message[data-user-identity]',
  function () {
    import(/* webpackChunkName: "popover-plugins" */ './plugins').then(() => {
      var $this = $(this);
      events.trigger('sendMessage', $this.data('user-identity'));
      $this
        .closest('[data-user-identity][data-fn-show-user-popup]')
        .webuiPopover('hide');
      return false;
    });
  }
);

$('body').on('click', 'button.sv-add-contact[data-user-identity]', function () {
  import(/* webpackChunkName: "popover-plugins" */ './plugins').then(() => {
    var $this = $(this);
    $.post(getModelObjectUri(sv.PageContext.userIdentityId, 'addContact'), {
      id: $this.data('user-identity'),
    });
    $(
      '[data-user-identity="' +
        $this.data('user-identity') +
        '"][data-fn-show-user-popup]'
    )
      .removeClass('user-popover-loaded')
      .webuiPopover('destroy');
    return false;
  });
});
