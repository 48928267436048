/**
* Copyright (C) SiteVision AB 2002-2019, all rights reserved
*
* moment.js language configuration
* language : finish (fi)
* https://github.com/moment/moment/blob/develop/locale/fi.js
*/
var lang = {
    months : 'tammikuu_helmikuu_maaliskuu_huhtikuu_toukokuu_kesäkuu_heinäkuu_elokuu_syyskuu_lokakuu_marraskuu_joulukuu'.split('_'),
    monthsShort : 'tammi_helmi_maalis_huhti_touko_kesä_heinä_elo_syys_loka_marras_joulu'.split('_'),
    weekdays : 'sunnuntai_maanantai_tiistai_keskiviikko_torstai_perjantai_lauantai'.split('_'),
    weekdaysShort : 'su_ma_ti_ke_to_pe_la'.split('_'),
    weekdaysMin : 'su_ma_ti_ke_to_pe_la'.split('_'),
    longDateFormat : {
        LT : 'HH.mm',
        L : 'DD.MM.YYYY',
        LL : 'Do MMMM[ta] YYYY',
        LLL : 'Do MMMM[ta] YYYY, [klo] HH.mm',
        LLLL : 'dddd, Do MMMM[ta] YYYY, [klo] HH.mm',
    },
    calendar : {
        sameDay : '[tänään] [klo] LT',
        nextDay : '[huomenna] [klo] LT',
        lastDay : '[eilen] [klo] LT',
        nextWeek : 'dddd [klo] LT',
        lastWeek : '[viime] dddd[na] [klo] LT',
        sameElse : 'L'
    },
    relativeTime : {
        future : "ihan äsken",
        past : "%s sitten",
        s : "ihan äsken",
        m : "1 min",
        mm : "%d min",
        h : "1 tunti",
        hh : "%d tuntia",
        d : "eilen",
        dd : "%d päivää",
        M : "1 kuu",
        MM : "%d kuu",
        y : "1 vuosi",
        yy : "%d vuotta"
    },
    dayOfMonthOrdinalParse: /\d{1,2}\./,
    ordinal : '%d.'
};

export default lang;