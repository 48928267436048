/*
 * Copyright (C) SiteVision AB 2002-2020, all rights reserved
 *
 * @author robin
 */
import sv from '@sv/core';
import _ from '@sv/underscore';
import $ from '@sv/jquery';
import {
  getModelObjectUri,
  getPortletResourceUri,
} from '../../util/portletUtil';
import {
  Ajax as ajax,
  DialogUtil as dialogUtil,
  Events as events,
  i18n as _i18n,
  ObjectUtil as objectUtil,
} from '@sv/util';

const i18cache = {},
  inputConfig = {
    triggers: {
      '@': {
        source: getModelObjectUri(
          sv.PageContext.pageId,
          'userIdentitySearch',
          sv.PageContext.pageId
        ),
        searchKey: 'term',
        allowWhiteSpace: true,
        maxWhiteSpace: 2,
        parse: function (response) {
          return response.hits;
        },
      },
    },
    minLength: 2,
    replacer: function (item) {
      return (
        item.trigger + '{"value":"' + item.name + '","id":"' + item.id + '"}'
      );
    },
  };

const i18n = function (selector, args) {
  var translated;

  if (!args && i18cache[selector]) {
    return i18cache[selector];
  }

  translated = _i18n.getText(
    'portlet.social.timeline.timeline',
    selector,
    args
  );

  if (!args) {
    i18cache[selector] = translated;
  }
  return translated;
};

const getOption = function (value, name) {
  return (
    '<option value="' + value + '" title="' + name + '">' + name + '</option>'
  );
};

const triggerNotification = function (type, message, data) {
  const options = {
    type: type,
    heading: i18n('sharedPageTitle'),
    message: message,
    transient: true,
    timer: 5,
  };

  if (data && data.targetUrl) {
    options.onClick = function () {
      location.href = data.targetUrl;
    };
  }

  events.trigger(events.types.notifyUser, options);
};

let maxCharacterCount, $shareButton;

const countdownCharactersLeft = function (e) {
  var $target = $(e.currentTarget),
    text = $target.val(),
    size = maxCharacterCount - text.length,
    $sizeField = e.data.$sizeField;

  if (size < 0) {
    $sizeField.addClass('sv-character-limit-exceeded');
  } else {
    $sizeField.removeClass('sv-character-limit-exceeded');
  }

  $sizeField.text(size);

  if (size >= 0) {
    getShareButton().removeClass('disabled').prop('disabled', false);
  } else {
    getShareButton().addClass('disabled').attr('disabled', 'disabled');
  }
};

const getShareButton = function () {
  if (!$shareButton) {
    $shareButton = $('.modal-footer button');
  }

  return $shareButton;
};

const sharePage = function (options) {
  var $modalContent = $('<div/>').addClass('sv-sharepage-portlet'),
    $commentLabel = $('<label/>')
      .attr('for', 'share-comment')
      .text(i18n('shareComment')),
    $messageField = $('<textarea/>')
      .prop('autofocus', true)
      .attr('id', 'share-comment')
      .addClass('sv-timeline-input sv-border-box'),
    $sizeField = $('<span/>')
      .addClass('sv-float-right')
      .text(maxCharacterCount),
    $targetLabel = $('<label/>')
      .attr('for', 'target-selection')
      .text(i18n('shareMessageContentTitle')),
    $targetSelection = $('<select/>')
      .addClass('input-xlarge')
      .attr('id', 'target-selection');

  $modalContent.append($commentLabel);
  $modalContent.append($messageField);
  $modalContent.append($sizeField);
  $modalContent.append($targetLabel);
  $modalContent.append($targetSelection);

  $messageField.on(
    'keyup',
    { $sizeField: $sizeField },
    countdownCharactersLeft
  );

  var buttons = [
    {
      text: i18n('share'),
      callback: function () {
        var target = $targetSelection.find(':selected')[0],
          sharePageContent;

        if (/^400\./.test(target.value)) {
          sharePageContent = i18n('sharedPageContentToPersonalTimeline');
        } else {
          sharePageContent = i18n(
            'sharedPageContentToGroupTimeline',
            target.title
          );
        }

        ajax.doPost({
          url: getPortletResourceUri(options.portletId, 'sharePage'),
          data: {
            message: $messageField.triggeredInput('getRichContent'),
            page: sv.PageContext.pageId,
            timelineOwner: target.value,
          },
          success: function (data) {
            triggerNotification('success', sharePageContent, data);
          },
          error: function () {
            triggerNotification('error', i18n('sharePageFail'));
          },
        });
      },
    },
  ];

  ajax
    .doGet({
      url: getPortletResourceUri(options.portletId, 'memberGroups'),
    })
    .done(function (groups) {
      var targets = getOption(
        sv.PageContext.userIdentityId,
        i18n('shareMessageContentToPersonalTimeline')
      );

      _.each(groups, function (memberGroup) {
        targets += getOption(memberGroup.id, memberGroup.name);
      });

      $targetSelection.append(targets);

      dialogUtil.showDialog({
        title: i18n('share') + ' ' + options.displayName,
        body: $modalContent,
        buttons: buttons,
        shownCallback: function () {
          $messageField.triggeredInput(inputConfig);
        },
      });
    });
};

$('.sv-sharepage-portlet').on('click', '.sv-share-page-button', function (e) {
  var $this = $(this),
    options = {
      portletId: objectUtil.getObjectId($(e.delegateTarget).attr('id')),
      displayName: $this.data('display-name'),
    };

  maxCharacterCount = $this.data('max-count');
  sharePage(options);
});
