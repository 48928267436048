/**
 * Copyright (C) SiteVision AB 2002-2020, all rights reserved
 *
 * @author Karl Eklöf
 */

import $ from '@sv/jquery';
import {
    Events as events,
} from '@sv/util';

$('.sv-sendmessage-portlet').each(function() {
   $(this).on('click', '.sv-fn-send-message', function() {
      events.trigger('sendMessage',  $(this).data('user-identity'));
      return false;
   });
});