/**
 * Copyright (C) SiteVision AB 2002-2020, all rights reserved
 *
 * @see usergroupsall.vm
 * @see usergroupsall.css
 * @see UserGroupsAllAction
 *
 * @author nh
 */
import sv from '@sv/core';
import $ from '@sv/jquery';
import Backbone from '@sv/backbone';
import { getPortletResourceUri, getTemplate } from '../../util/portletUtil';
import { showSearchPopover } from '../util/addGroupUtil';
import {
  Ajax as ajax,
  DialogUtil as dialogUtil,
  Events as events,
  ObjectUtil as objectUtil,
  ErrorUtil as errorUtil,
  i18n as _i18n,
} from '@sv/util';

const getText = function (key, args) {
    return _i18n.getText(
      'portlet.social.usergroupsall.usergroupsall',
      key,
      args
    );
  },
  SocialGroup = Backbone.Model.extend({
    url: function () {
      var groupId = this.get('id');
      return this.collection.url() + '&group=' + groupId;
    },
  }),
  SocialGroupCollection = Backbone.Collection.extend({
    model: SocialGroup,

    initialize: function (models, options) {
      this.portletId = options.portletId;
    },

    url: function () {
      return getPortletResourceUri(this.portletId, 'groupMembership');
    },
  }),
  SocialGroupView = Backbone.View.extend({
    tagName: 'li',

    // CSS on <li> when rendered using backbone (should match <li> in usergroupsall.vm)
    className: 'sv-clearfix sv-usergroup-list-item',

    // Connect 'leave group' link with 'destroy' function
    events: {
      'click [data-fn-usergroup-leave]': 'leaveGroup',
      'click [data-fn-usergroup-remove]': 'removeGroup',
    },

    initialize: function () {
      this.template = this.options.template;
      this.portletId = this.options.portletId;
      this.bindModelEvents();
    },

    bindModelEvents: function () {
      // Listen on 'destroy' that will be triggered if the group is removed from the collection
      this.model.on('destroy', this.removeFromView, this);

      // Listen on 'filter' in order to toggle visibility when the filter is modified
      this.model.on('filterModified', this.filterWasModified, this);
    },

    // Render HTML for group
    render: function () {
      this.$el.html(this.template(this.model.toJSON(), this.model));
      return this;
    },

    // Make a request to the server and leave (membership removal) the group
    leaveGroup: function () {
      this.model.destroy({
        wait: true,
        error: function (model, xhr, options) {
          errorUtil.handleBackboneError(model, xhr, options);
        },
      });
      return false;
    },

    // Make a request to the server and remove the group
    removeGroup: function () {
      var groupView = this;
      var group = groupView.model;
      var groupName = group.get('name');
      var groupId = group.get('id');

      dialogUtil.showConfirmDialog(
        getText('removeGroupTitle'),
        getText('removeGroupMessage', groupName),
        function (result) {
          if (result) {
            ajax.doDelete({
              url:
                getPortletResourceUri(
                  groupView.portletId,
                  'groupAdministration'
                ) +
                '&group=' +
                groupId,
              success: function () {
                // Remove from view and show an message for the user
                groupView.$el.fadeOut('3000', function () {
                  groupView.$el.remove();
                  events.trigger(events.types.notifyUser, {
                    type: 'success',
                    heading: getText('removedGroupHeading'),
                    message: getText('removedGroupMessage', groupName),
                    transient: true,
                    timer: 5,
                  });
                });
              },
            });
          }
        }
      );
    },

    // Group has been removed at the server, remove from the list
    removeFromView: function () {
      var that = this;
      var groupName = this.model.get('name');

      this.$el.fadeOut('3000', function () {
        that.$el.remove();
        events.trigger(events.types.notifyUser, {
          type: 'success',
          heading: getText('leftGroupHeading'),
          message: getText('leftGroupMessage', groupName),
          transient: true,
          timer: 5,
        });
      });
    },

    // Filter was modified, toggle visibility if group doesn't match the filter
    filterWasModified: function (opts) {
      var filterStr = opts.filterStr;
      this.$el.toggleClass('env-d--none', !this.isVisible(filterStr));
    },

    isVisible: function (filterStr) {
      var groupName = this.model.get('name');
      return (
        !filterStr ||
        (groupName &&
          groupName.toLowerCase().indexOf(filterStr.toLowerCase()) !== -1)
      );
    },
  }),
  SocialGroupCollectionView = Backbone.View.extend({
    tagName: 'ol',

    initialize: function () {
      this.groupTemplate = this.options.groupTemplate;
      this.portletId = this.options.portletId;
      this.list = this.options.list;
      this.groupPageTemplates = this.options.groupPageTemplates;
      this.groupFolder = this.options.groupFolder;
      this.groupTypes = this.options.groupTypes;
      this.currentListStyle = this.options.list.data('current-list-style');
      // Register a listener that will trigger when a group is added to the model
      this.collection.on('add', this.groupAddedToModel, this);
    },

    // Connect 'create group' button with create group function
    events: {
      'click [data-fn-usergroup-create]': 'addGroup',
      'click [data-fn-change-list-style]': 'changeListStyle',
      'keyup [data-fn-filter]': 'filterWasModified',
      'change [data-fn-filter]': 'filterWasModified',
      'blur [data-fn-filter]': 'filterWasModified',
    },

    render: function () {
      this.clearList();

      this.collection.each(function (item) {
        this.appendOne(item);
      }, this);

      // Trigger 're-rendering' of timeline latest update date
      events.trigger(events.types.updateRelativeDates, this.list);
    },

    appendOne: function (group) {
      var groupView = new SocialGroupView({
        model: group,
        portletId: this.portletId,
        template: this.groupTemplate,
      });

      this.list.append(groupView.render().el);
    },

    prependOne: function (group) {
      var groupView = new SocialGroupView({
        model: group,
        template: this.groupTemplate,
      });

      this.list.append(groupView.render().el);
    },

    clearList: function () {
      this.list.empty();
    },

    // Group added to the model, add to the list
    groupAddedToModel: function (group, collection, options) {
      if (options.append) {
        this.appendOne(group);
      } else {
        this.prependOne(group);
      }
    },

    // Add or Create group
    addGroup: function (e) {
      showSearchPopover({
        target: e.currentTarget,
        placeholder: getText('groupName'),
        groupTemplates: this.groupPageTemplates,
        groupFolder: this.groupFolder,
        groupTypes: this.groupTypes,
        portletId: this.portletId,
        selected: function (value) {
          location.href = value.uri;
        },
      });

      return false;
    },

    // Change the layout of the list
    changeListStyle: function (e) {
      var button = $(e.currentTarget);
      var newListStyle = button.data('list-style');
      this.list.removeClass(this.currentListStyle).addClass(newListStyle);
      this.currentListStyle = newListStyle;
    },

    // Filter was modified by a user
    filterWasModified: function (e) {
      var filterStr = e.currentTarget.value;

      this.collection.each(function (group) {
        group.trigger('filterModified', { filterStr: filterStr });
      }, this);
    },
  });

$('.sv-usergroupsall-portlet').each(function () {
  var portletDiv = $(this);
  var portletId = objectUtil.getObjectId(portletDiv.attr('id'));
  var list = portletDiv.find('ol');
  var jsNamespace = list.data('js-namespace');
  var portletSettings = window.sv[jsNamespace];
  var groupTemplate = getTemplate(portletDiv, 'usergroupsall-group');

  // Show 'label placeholder' for browsers that doesn't support HTML5 placeholders
  if (!sv.ClientUtil.supportsPlaceholder) {
    portletDiv.find('.sv-filter-label').removeClass('env-d--none');
  }

  //var groupPageTemplate = list.data('group-template');
  var groupFolder = list.data('group-folder');
  var groupTypes = list.data('group-types').split(',');
  var groups = portletSettings.groups;

  var groupCollection = new SocialGroupCollection(groups, {
    portletId: portletId,
  });
  var groupCollectionView = new SocialGroupCollectionView({
    el: portletDiv,
    portletId: portletId,
    collection: groupCollection,
    groupTemplate: groupTemplate,
    groupPageTemplates: portletSettings.templates,
    groupFolder: groupFolder,
    groupTypes: groupTypes,
    list: list,
  });

  groupCollectionView.render();

  portletDiv
    .find('li.sv-usergroup-list-item')
    .css('height', list.data('item-height'));
});
