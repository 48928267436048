/**
 * Copyright (C) SiteVision AB 2002-2020, all rights reserved
 *
 * handles the online server events. To subscribe or unsubscribe to a channel push the operation to
 * sv._serverEventChannels. Any preregistered channels will be subscribed when setting up the meta channel.
 * After the initial call additions to sv._serverEventChannels will trigger a new server call setting up the specific
 * channel
 *
 * ex:
 *   sv._serverEventChannels.push({ subscribe: 'aChannel' }); // subscribe to aChannel
 *   sv._serverEventChannels.push({ unsubscribe: 'aChannel' }); // unsubscribe to aChannel
 *
 * @author micke
 */
import sv from '@sv/core';
import _ from '@sv/underscore';
import { getModelObjectUri } from '../../util/portletUtil';
import { Ajax as ajax, Events as events, Log as log } from '@sv/util';
import atmosphere from 'atmosphere.js';

const PAGE_CONTEXT = sv.PageContext,
  PROTOCOL = location.protocol,
  HOST = location.host,
  META_CHANNEL_URL = PROTOCOL + '//' + HOST + '/svevent/online/meta',
  userIdentityId = PAGE_CONTEXT.userIdentityId,
  SUBSCRIBE_URL = getModelObjectUri(userIdentityId, 'serverEventChannel'),
  UNSUBSCRIBE_URL = getModelObjectUri(
    userIdentityId,
    'serverEventChannel',
    userIdentityId
  ),
  ENABLED = PAGE_CONTEXT.useServerSideEvents;

var state;

/**
 * an array like implementation supporting push
 */
function ServerEventChannels() {
  this.push = function (options) {
    var op = options.subscribe ? subscribe : unsubscribe,
      channel = options.subscribe ? options.subscribe : options.unsubscribe;

    if (state) {
      // connections is opened
      executeWhenConnected(op, [channel]);
    } else if (options.subscribe) {
      // just ignore any unsubscribe
      initializeServerEvents([channel]);
    }
  };
}

if (ENABLED) {
  const LOG_LEVEL = log.getTraceLevels();
  const TRACE = 'serverEvents';

  let resourceUuid;
  const intervalTimerIds = {};
  const channels = [];
  let req;

  const clearIntervals = (intervalIds) =>
    intervalIds.forEach((intervalId) => clearInterval(intervalId));

  var executeWhenConnected = function (closure, args) {
      switch (state) {
        case 'open':
          closure.apply(this, args);
          break;
        case 'connecting': {
          const intervalKey = args.toString();
          intervalTimerIds[intervalKey] = intervalTimerIds[intervalKey] || [];
          intervalTimerIds[intervalKey].push(
            setInterval(function () {
              if (state === 'open') {
                closure.apply(this, args);
                clearIntervals(intervalTimerIds[intervalKey]);
              } else if (state !== 'connecting') {
                log.error(
                  'Aborting operation. Connection could not be opened. State is ' +
                    state
                );
                clearIntervals(intervalTimerIds[intervalKey]);
              }
            }, 1000)
          ); // 1s
          break;
        }
        default:
          log.error(
            'Aborting operation. Unable to open connection. State is ' + state
          );
      }
    },
    subscribe = function (channel) {
      log.debug(TRACE, 'subscribing to ' + channel);

      return ajax
        .doPost({
          url: SUBSCRIBE_URL,
          data: {
            channel: channel,
            resource: resourceUuid,
          },
        })
        .done(function () {
          log.debug(TRACE, 'successfully subscribed to ' + channel);

          //see jira SV-16142
          req.request.url = req.request.url + ',' + channel;
          events.trigger('_server-event-connected-' + channel);
        })
        .fail(function (jqXHR) {
          log.error(
            TRACE,
            'Failed to subscribe to ' + channel + ':' + JSON.stringify(jqXHR)
          );
          events.trigger('_server-event-failed-' + channel);
        });
    },
    unsubscribe = function (channel) {
      log.debug(TRACE, 'unsubscribing to ' + channel);

      return ajax
        .doDelete({
          url: UNSUBSCRIBE_URL,
          processData: false,
          data: window.JSON.stringify({
            channel: channel,
            resource: resourceUuid,
          }),
        })
        .done(function () {
          log.debug(TRACE, 'successfully unsubscribed to ' + channel);

          //see jira SV-16142
          req.request.url = req.request.url.replace(
            new RegExp(',' + channel),
            ''
          );
        })
        .fail(function (jqXHR) {
          log.error(
            TRACE,
            'Failed to unsubscribe to ' + channel + ':' + JSON.stringify(jqXHR)
          );
        });
    },
    getMetaChannelRequest = function (channels) {
      return {
        url:
          META_CHANNEL_URL +
          '/' +
          PAGE_CONTEXT.siteId +
          '?channels=' +
          channels.join(','),
        contentType: 'application/json',
        logLevel: LOG_LEVEL,
        transport: 'websocket',
        connectTimeout: 5000,
        fallbackTransport: 'long-polling',

        onMessage: function (response) {
          var messages = _.filter(
            response.responseBody.split('\uF07C'),
            function (message) {
              return message !== '';
            }
          );

          log.debug(TRACE, 'Received message ' + response.responseBody);

          _.each(messages, function (m) {
            var message;

            try {
              message = JSON.parse(m);
            } catch (e) {
              log.error('Could not parse message ' + m, e);
              return;
            }

            events.handleServerSideEvent(message.channel, message);
          });
        },

        onOpen: function (response) {
          log.info(
            TRACE,
            'Connected to ' +
              response.request.url +
              ' using ' +
              response.transport
          );
          log.debug(TRACE, 'Connection uuid is ' + response.request.uuid);
          resourceUuid = response.request.uuid;

          channels.forEach(function (channel) {
            events.trigger('_server-event-connected-' + channel);
          });

          state = 'open';
        },

        onReconnect: function () {
          log.info(TRACE, 'Reconnecting to server ...');
        },

        onError: function (response) {
          log.error(
            TRACE,
            'Connection error. Problem with connection or server is down ' +
              response.error +
              ' ' +
              response.responseBody
          );
          state = 'error';

          channels.forEach(function (channel) {
            events.trigger('_server-event-failed-' + channel);
          });
        },

        onClose: function () {
          log.info(TRACE, 'Connection closed for ' + resourceUuid);
          resourceUuid = undefined;
          state = 'closed';
        },

        onTransportFailure: function (errorMessage, request) {
          log.error(
            TRACE,
            'Connection error. Transport failure ' +
              request.url +
              ', ' +
              errorMessage
          );
        },

        onFailureToReconnect: function (request, response) {
          log.error(
            TRACE,
            'Reconection failure to ' + request.url + ' error ' + response.error
          );
        },

        onClientTimeout: function (request) {
          log.error(TRACE, 'Client timed out to ' + request.url);
        },
      };
    },
    initializeServerEvents = function (channels) {
      var request = getMetaChannelRequest(channels);

      log.info(TRACE, 'Subscribing to url ' + request.url);
      state = 'connecting';
      req = atmosphere.subscribe(request);
    };

  if (sv._serverEventChannels) {
    _.each(sv._serverEventChannels, function (options) {
      if (options.subscribe) {
        channels.push(options.subscribe);
      }
    });
  }

  if (channels.length) {
    initializeServerEvents(channels);
  }

  sv._serverEventChannels = new ServerEventChannels();
}
